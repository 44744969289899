import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Fab, Tooltip } from '@material-ui/core';
import { PostAdd } from '@material-ui/icons';

const useStyles = makeStyles((theme: Theme) => ({
  addButton: {
    position: 'fixed',
    bottom: theme.spacing(3),
    right: theme.spacing(3),
  },
}));

interface AddSnippetFloatingButtonProps {
  handleClickAdd: () => void
}

const AddSnippetFloatingButton: React.FC<AddSnippetFloatingButtonProps> = ({ handleClickAdd }: AddSnippetFloatingButtonProps) => {
  const classes = useStyles();

  return (
    <Tooltip title='Add Snippet'>
      <div onClick={handleClickAdd} role='presentation' className={classes.addButton}>
        <Fab color='secondary'>
          <PostAdd />
        </Fab>
      </div>
    </Tooltip>
  );
}

export default AddSnippetFloatingButton;