export const datetimeToString = (date: Date | string | null) => {

  if (date === null) {
      return ''
  } else if (typeof date === 'string') {
      const dateFormat = new Date(date);
      return dateFormat.toString();
  } else {
      return date.toString()
  }
}

export const datetimeToDateString = (date: Date | string | null) => {

  if (date === null) {
      return ''
  } else if (typeof date === 'string') {
      const dateFormat = new Date(date);
      // return dateFormat.toString();
    //   return dateFormat.toLocaleDateString();
      return dateFormat.toLocaleString();

  } else {
      return date.toString()
  }
}