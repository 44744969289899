export interface CollectionInterface {
  id: number;
  collection_name: string;
  date_created: string;
  user_id: number;
};

export interface CollectionListLoadStateInterface {
  isLoading: boolean;
  isError: boolean;
  data: Array<CollectionInterface>;
};

export const initialCollectionList: Array<CollectionInterface> = [
  {
    id: 0,
    collection_name: '',
    date_created: '',
    user_id: 0
  }
];