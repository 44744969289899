export const MODES = [
  {
    name: 'JavaScript',
    value: 'javascript'
  },
  {
    name: 'TypeScript',
    value: 'typescript'
  },
  {
    name: 'Python',
    value: 'python'
  },
  {
    name: 'Java',
    value: 'java'
  },
  {
    name: 'R',
    value: 'r'
  },
  {
    name: 'Go',
    value: 'golang'
  },
  {
    name: 'C / C++',
    value: 'c_cpp'
  },
  {
    name: 'C#',
    value: 'csharp'
  },
  {
    name: 'Swift',
    value: 'swift'
  },
  {
    name: 'PHP',
    value: 'php'
  },
  {
    name: 'Ruby',
    value: 'ruby'
  },
  {
    name: 'OCaml',
    value: 'ocaml'
  },
  {
    name: 'Scala',
    value: 'scala'
  },
  {
    name: 'Haskell',
    value: 'haskell'
  },
  {
    name: 'Julia',
    value: 'julia'
  },
  {
    name: 'MATLAB',
    value: 'matlab'
  },
  {
    name: 'React JavaScript',
    value: 'jsx'
  },
  {
    name: 'React TypeScript',
    value: 'tsx'
  },
  {
    name: 'HTML',
    value: 'html'
  },
  {
    name: 'CSS',
    value: 'css'
  },
  {
    name: 'Dockerfile',
    value: 'dockerfile'
  },
  {
    name: 'Gitignore',
    value: 'gitignore'
  },
  {
    name: 'Markdown',
    value: 'markdown'
  },
  {
    name: 'Plain Text',
    value: 'plain_text'
  },
  {
    name: 'MySQL',
    value: 'mysql'
  },
  {
    name: 'JSON',
    value: 'json'
  }  
]

// Splitting modes into categories: List subheader buggy
// export const MODES = {
//   noCategory: [
//     {
//       name: 'Plain Text',
//       value: 'plain_text'
//     },
//     {
//       name: 'Markdown',
//       value: 'markdown'
//     },
//   ],
//   languages: [
//     {
//       name: 'JavaScript',
//       value: 'javascript'
//     },
//     {
//       name: 'TypeScript',
//       value: 'typescript'
//     },
//     {
//       name: 'Python',
//       value: 'python'
//     },
//     {
//       name: 'Java',
//       value: 'java'
//     },
//     {
//       name: 'R',
//       value: 'r'
//     },
//     {
//       name: 'Go',
//       value: 'golang'
//     },
//     {
//       name: 'C / C++',
//       value: 'c_cpp'
//     },
//     {
//       name: 'C#',
//       value: 'csharp'
//     },
//     {
//       name: 'Swift',
//       value: 'swift'
//     },
//     {
//       name: 'PHP',
//       value: 'php'
//     },
//     {
//       name: 'Ruby',
//       value: 'ruby'
//     },
//     {
//       name: 'OCaml',
//       value: 'ocaml'
//     },
//     {
//       name: 'Scala',
//       value: 'scala'
//     },
//     {
//       name: 'Haskell',
//       value: 'haskell'
//     },
//     {
//       name: 'Julia',
//       value: 'julia'
//     },
//     {
//       name: 'MATLAB',
//       value: 'matlab'
//     },
//     {
//       name: 'HTML',
//       value: 'html'
//     },
//     {
//       name: 'CSS',
//       value: 'css'
//     },
//     {
//       name: 'MySQL',
//       value: 'mysql'
//     },
//     {
//       name: 'JSON',
//       value: 'json'
//     }
//   ],
//   frameworks: [
//     {
//       name: 'React JavaScript',
//       value: 'jsx'
//     },
//     {
//       name: 'React TypeScript',
//       value: 'tsx'
//     },
//   ],
//   tools: [
//     {
//       name: 'Dockerfile',
//       value: 'dockerfile'
//     },
//     {
//       name: 'Gitignore',
//       value: 'gitignore'
//     },
//   ],
// }
