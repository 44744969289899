import JavaScript from '../../assets/icons/javascript.svg';
import TypeScript from '../../assets/icons/typescript.svg';
import Java from '../../assets/icons/java.svg';
import Go from '../../assets/icons/golang.svg';
import CPlusPlus from '../../assets/icons/cpp.svg';
import CSharp from '../../assets/icons/csharp.svg';
import Swift from '../../assets/icons/swift.svg';
import Scala from '../../assets/icons/scala.svg';
import Python from '../../assets/icons/python.svg';
import RLanguage from '../../assets/icons/r.svg';
import Ruby from '../../assets/icons/ruby.svg';
import Php from '../../assets/icons/php.svg';
import MySQL from '../../assets/icons/mysql.svg';
import OCaml from '../../assets/icons/ocaml.svg';
import Haskell from '../../assets/icons/haskell.svg';
import Julia from '../../assets/icons/julia.svg';
import Matlab from '../../assets/icons/matlab.svg';
import React from '../../assets/icons/react.svg';
import Html from '../../assets/icons/html.svg';
import Css from '../../assets/icons/css.svg';
import Dockerfile from '../../assets/icons/dockerfile.svg';
import Gitignore from '../../assets/icons/gitignore.svg';
import Markdown from '../../assets/icons/markdown.svg';
import PlainText from '../../assets/icons/plain_text.svg';
import Json from '../../assets/icons/json.svg';

export const getModeIcon = (mode: string) => {
  switch (mode) {
    case 'javascript':
      return JavaScript;
    case 'typescript':
      return TypeScript;
    case 'java':
      return Java;
    case 'golang':
      return Go;
    case 'c_cpp':
      return CPlusPlus
    case 'csharp':
      return CSharp;
    case 'swift':
      return Swift;
    case 'scala':
      return Scala;
    case 'python':
      return Python;
    case 'r':
      return RLanguage;
    case 'ruby':
      return Ruby;
    case 'mysql':
      return MySQL;
    case 'php':
      return Php;
    case 'ocaml':
      return OCaml;
    case 'haskell':
      return Haskell;
    case 'julia':
      return Julia;
    case 'matlab':
      return Matlab;
    case 'jsx':
    case 'tsx':
      return React;
    case 'html':
      return Html;
    case 'css':
      return Css;
    case 'dockerfile':
      return Dockerfile;
    case 'gitignore':
        return Gitignore;
    case 'markdown':
      return Markdown;
    case 'plain_text':
      return PlainText;
    case 'json':
      return Json;
  }
  return ''
}