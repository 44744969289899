import React, { useEffect, useState } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Button, FormControl, Grid, TextField } from '@material-ui/core';
import { useCallApi } from '../../@utils';
import { GenericResponseLoadState, initialGenericResponse, Options } from '../../@types';
import { useLocation } from 'react-router-dom';
import { Save } from '@material-ui/icons';

const useStyles = makeStyles((theme: Theme) => ({
  optionsGroup: {
    display: 'flex',
    justifyContent: 'space-evenly',
  },
  formfield: {
    width: '100%',
    marginBottom: 20,
    '& .MuiIconButton-root': {
      color: theme.palette.text.secondary,
      padding: 8,
    },
  },
  buttonGroup: {
    display: 'flex',
    justifyContent: 'space-between',
    '& > * ': {
      boxShadow: 'none',
      width: '48%',
    },
  },
}));

interface EditCollectionFormProps {
  userID: number;
  triggerClose: () => void;
  setMessage: React.Dispatch<React.SetStateAction<string>>;
  collectionID: number;
  collectionName: string;
};

const EditCollectionForm: React.FC<EditCollectionFormProps> = ({ userID, triggerClose, setMessage, collectionID, collectionName }: EditCollectionFormProps) => {
  const classes = useStyles();
  const location = useLocation();

  const [form, setForm] = useState({
    collection_name: collectionName
  });

  const [isFilled, setIsFilled] = useState({
    collection_name: true
  });

  const [editCollectionLoadState, editCollectionOptions]: [GenericResponseLoadState, Options] = useCallApi(
    `collection/edit/${collectionID}/`,
    initialGenericResponse,
    'PUT',
    false,
    'PRIVATE'
  );

  const handleSubmit = () => {
    //Show Errors
    for (let [field, value] of Object.entries(form)) {
      setIsFilled(prev => ({
        ...prev,
        [field]: !value ? false : true
      }));
    };

    // Validation
    if (!Object.values(form).includes('')) {
      editCollectionOptions.setData({
        collection_name: form.collection_name,
      })
    };
  };

  useEffect(() => {
    if (editCollectionLoadState.data.success) {
      setMessage('Collection Successfully Updated!')
      if (location.pathname === '/dashboard') window.location.reload();
    }

    // Delay on closing dialog to prevent memory leak on unmount
    const delay = setInterval(() => {
      if (editCollectionLoadState.data.success) triggerClose();
    }, 10)
    return () => clearInterval(delay);
  }, [editCollectionLoadState.data.success, location.pathname, triggerClose, setMessage]);

  // Handler for repeated collection name
  const [collectionNameError, setCollectionNameError] = useState<boolean>(false);

  useEffect(() => {
    if (editCollectionOptions.error.error) {
      setCollectionNameError(true);
    }
  }, [editCollectionOptions.error.error]);

  return (
    <Grid container spacing={0}>
      <Grid item xs={12} className={classes.optionsGroup}>
        <FormControl className={classes.formfield} component="fieldset">
          <TextField
            fullWidth
            id="collection_name"
            name="Collection Name"
            label='Collection Name'
            autoComplete="off"
            variant="filled"
            color="secondary"
            defaultValue={collectionName}
            inputProps={{ maxLength: 20 }}
            onChange={(e) => {
              setForm(prev => ({
                ...prev,
                collection_name: e.target.value
              }));
              setIsFilled(prev => ({
                ...prev,
                collection_name: e.target.value.length === 0 ? false : true
              }));
              setCollectionNameError(false);
              e.persist();
            }}
            error={collectionNameError || !isFilled.collection_name}
            helperText={collectionNameError ? 'Collection Name already exists!' : isFilled.collection_name ? `${form.collection_name.length}/20` : 'Collection Name cannot be blank'}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} className={classes.buttonGroup}>
        <Button variant='contained' onClick={triggerClose}>
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          startIcon={<Save />}
          disabled={form.collection_name === collectionName}
        >
          Save!
        </Button>
      </Grid>
    </Grid>
  )
}

export default EditCollectionForm