export interface PopularStatsInterface {
  mode: string;
};

export interface PopularStatsLoadStateInterface {
  isLoading: boolean;
  isError: boolean;
  data: Array<PopularStatsInterface>;
};

export const initialPopularStats: Array<PopularStatsInterface> = [
  {
    mode: ''
  }
];