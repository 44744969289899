import React, { useContext } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Button, Card, CardContent, CardHeader, FormControl, FormControlLabel, Grid, Switch, Tooltip } from '@material-ui/core';
import { ThemeContext } from '../../context/ThemeContext';
import SettingsInnerGridLayout from '../layouts/SettingsInnerGridLayout';
import { version } from '../../../package.json';

const useStyles = makeStyles<Theme>((theme: Theme) => ({
  installButton: {
    marginLeft: 16,
    marginRight: 16,
  },
}));

interface AppearanceSettingsCardProps {
  supportsPWA: boolean;
  promptToInstall: () => void;
}

const AppearanceSettingsCard: React.FC<AppearanceSettingsCardProps> = ({ supportsPWA, promptToInstall }: AppearanceSettingsCardProps) => {
  const classes = useStyles();
  const { darkMode, toggleTheme } = useContext(ThemeContext);

  return (
    <Card>
      <CardHeader
        title="Appearance"
      />
      <CardContent>
        <Grid container spacing={0}>
          <SettingsInnerGridLayout
            title="Toggle Theme"
            component={
              <FormControl component="fieldset">
                <FormControlLabel
                  control={<Switch checked={darkMode} onChange={toggleTheme} name="Theme" />}
                  label={darkMode ? 'Dark' : 'Light'}
                  labelPlacement="start"
                />
              </FormControl>
            }
          />
          <SettingsInnerGridLayout
            title="Install as App"
            subtitle={`Version: ${version}`}
            component={
              <Tooltip title={supportsPWA ? 'Install CodeCache as an app to improve your experience!' : 'Installation not supported in current browser. Either you already have installed CodeCache, or please try again with another browser.'}>
                <div>
                  <Button
                    className={classes.installButton}
                    variant="contained"
                    color="secondary"
                    onClick={() => promptToInstall()}
                    disabled={!supportsPWA}
                  >
                    Install!
                  </Button>
                </div>
              </Tooltip>
            }
          />
        </Grid>
      </CardContent>
    </Card>
  )
}

export default AppearanceSettingsCard