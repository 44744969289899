import React, { useState, createContext, useEffect } from 'react';
import { GenericResponseLoadState, initialGenericResponse, Options } from '../@types';
import { useCallApi } from '../@utils';
import ResponseSnackbar from '../components/utility/ResponseSnackbar';

export const UserDefaultsContext = createContext(
  {
    userID: 0,
    setUserID: (userID: number) => { },
    defaultMode: '',
    setDefaultMode: (defaultMode: string) => { },
    defaultTheme: '',
    setDefaultTheme: (defaultTheme: string) => { },
    handleSaveChanges: () => { }
  }
)

const UserDefaultsContextProvider: React.FC = ({children}) => {
  const [userID, setUserID] = useState<number>(0);
  const [defaultMode, setDefaultMode] = useState<string>('');
  const [defaultTheme, setDefaultTheme] = useState<string>('');

  const [editUserLoadState, editUserOptions]: [GenericResponseLoadState, Options] = useCallApi(
    `authentication/edit/${userID}/`,
    initialGenericResponse,
    'PUT',
    false,
    'PRIVATE'
  );

  const handleSaveChanges = () => {
    editUserOptions.setData({
      default_programming_language: defaultMode,
      default_theme: defaultTheme
    });
  };

  // Snackbar Handle Success
  const [message, setMessage] = useState<string>('');

  useEffect(() => {
    if (editUserLoadState.data.success) {
      window.location.reload();
      setMessage('Preferences Successfully Updated!')
    } else {
      return
    }
  }, [editUserLoadState.data.success]);

  return (
    <UserDefaultsContext.Provider
      value={{
        userID: userID,
        setUserID: setUserID,
        defaultMode: defaultMode,
        setDefaultMode: setDefaultMode,
        defaultTheme: defaultTheme,
        setDefaultTheme: setDefaultTheme,
        handleSaveChanges: handleSaveChanges,
      }}
    >
      {children}
      <ResponseSnackbar message={message} setMessage={setMessage} severity='success' />
    </UserDefaultsContext.Provider>
  );
};

export default UserDefaultsContextProvider;