import { environment } from '../../package.json';
import { AxiosRequestConfig } from 'axios';

let localUrl = 'http://localhost:8000/v1/';
let localDockerUrl = 'http://localhost:80/v1/';
let prodUrl = 'https://api.codecache.io/v1/';


const apiURL = () => {
  switch (environment) {
    case 'local':
      return localUrl;
    case 'local-docker':
      return localDockerUrl;
    case 'prod':
      return prodUrl;
    default:
      return localUrl;
  }
}

const API_CONFIG: AxiosRequestConfig = {
  baseURL: apiURL(),
  responseType: "json",
  method: "GET",
  headers: {
    'X-XSS-Protection': '1; mode=block',
    'X-Frame-Options': 'DENY',
    'X-Content-Type-Options': 'nosniff',
    'Strict-Transport-Security': 'max-age=63072000; includeSubDomains; preload',
  },
}

export { API_CONFIG };