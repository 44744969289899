import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Grid, Link } from '@material-ui/core';
import { GitHub, Instagram, Language, LinkedIn, Mail } from '@material-ui/icons';

const useStyles = makeStyles<Theme>((theme: Theme) => ({
  social: {
    paddingTop: 30,
    paddingBottom: 30,
    '& .MuiGrid-root': {
      display: 'flex',
      justifyContent: 'space-evenly',
      alignItems: 'center',
      textAlign: 'center',
      '& .MuiSvgIcon-root': {
        fontSize: '1.5rem',
      },
    },
  },
}));

const CreatorsSocialButtons = () => {
  const classes = useStyles();

  return (
    <Grid container spacing={0} className={classes.social}>
      <Grid item xs={12}>
        <Link href='https://www.leonardtng.com/' target='_blank' rel="noopener" aria-label="Personal Website" >
          <Language color="secondary" />
        </Link>
        <Link href='https://www.linkedin.com/in/leonard-tng/' target='_blank' rel="noopener" aria-label="LinkedIn" >
          <LinkedIn color="secondary" />
        </Link>
        <Link href='mailto:theleonardtng@gmail.com' rel="noopener" aria-label="Email" >
          <Mail color="secondary" />
        </Link>
        <Link href='https://www.github.com/leonardtng/' target='_blank' rel="noopener" aria-label="GitHub" >
          <GitHub color="secondary" />
        </Link>
        <Link href='https://www.instagram.com/leonard.tng/' target='_blank' rel="noopener" aria-label="Instagram" >
          <Instagram color="secondary" />
        </Link>
      </Grid>
    </Grid>
  )
}

export default CreatorsSocialButtons
