export interface DialogFeaturesInterface {
    open: boolean;
    title?: string;
    subtitle?: React.ReactNode | null;
    color?: string;
    width?: number | string;
    icon?: React.ReactNode | null;
    primaryAction?: {
        buttonText?: string;
        buttonFunction?: () => void;
        buttonPrimaryColor?: string;
    };
    customSecondaryAction?: {
        buttonText?: string;
        buttonFunction?: () => void
        buttonSecondaryColor?: string;
    };
    message?: string;
    customBodyActions?: React.ReactNode;
    confetti?: boolean;
    customCancelAction?: () => void;
    cancelButton?: boolean;
};

export const initialDialogFeatures: DialogFeaturesInterface = {
    open: false,
    title: '',
    icon: null,
    color: '',
    message: '',
    primaryAction: {
        buttonText: '',
        buttonFunction: () => { },
    },
};