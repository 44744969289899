import React, { useEffect, useState } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Button, Card, CardContent, CardHeader, FormControl, Grid, IconButton, InputAdornment, TextField } from '@material-ui/core';
import { ChangePassword, ChangePasswordIsFilled, GenericResponseLoadState, initialGenericResponse, Options } from '../../@types';
import SettingsInnerGridLayout from '../layouts/SettingsInnerGridLayout';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { useCallApi } from '../../@utils';
import ResponseSnackbar from '../utility/ResponseSnackbar';

const useStyles = makeStyles((theme: Theme) => ({
  nameField: {
    width: '100%',
    '& .MuiInputBase-input': {
      textAlign: 'center',
    },
  },
  saveButton: {
    textAlign: 'center',
  },
  '@media only screen and (max-width: 960px)': {
    avatarlarge: {
      width: '20vw',
      height: '20vw',
    },
  }
}));

interface ChangePasswordCardProps {
  userID: number
}

const ChangePasswordCard: React.FC<ChangePasswordCardProps> = ({ userID }: ChangePasswordCardProps) => {
  const classes = useStyles();

  const [changePasswordLoadState, changePasswordOptions]: [GenericResponseLoadState, Options] = useCallApi(
    `authentication/edit/password/${userID}/`,
    initialGenericResponse,
    'PUT',
    false,
    'PRIVATE'
  );

  const [form, setForm] = useState<ChangePassword>({
    old_password: '',
    new_password: '',
    re_new_password: '',
  });

  const [isFilled, setIsFilled] = useState<ChangePasswordIsFilled>({
    old_password: true,
    new_password: true,
    re_new_password: true,
  });

  const [showOldPassword, setShowOldPassword] = useState<boolean>(false);
  const [showNewPassword, setShowNewPassword] = useState<boolean>(false);
  const [showReNewPassword, setShowReNewPassword] = useState<boolean>(false);
  const [passwordError, setPasswordError] = useState<boolean>(false);

  const handleSubmit = () => {
    // Show Errors
    for (let [field, value] of Object.entries(form)) {
      setIsFilled(prev => ({
        ...prev,
        [field]: !value ? false : true
      }));
    };

    if (form.new_password !== form.re_new_password) {
      setPasswordError(true);
    };

    // Validation
    if (!Object.values(form).includes('') && (form.new_password === form.re_new_password)) {
      changePasswordOptions.setData(form);
    };
  };

  // Snackbar Handle Success for Change Password
  const [message, setMessage] = useState<string>('');

  useEffect(() => {
    if (changePasswordLoadState.data.success) {
      window.location.reload();
      setMessage('Password Successfully Updated!');
    } else {
      return
    }
  }, [changePasswordLoadState.data.success]);

  return (
    <Card>
      <CardHeader
        title="Change Password"
      />
      <CardContent>
        <Grid container spacing={0}>
          <SettingsInnerGridLayout
            title="Current Password"
            buffer
            component={
              <FormControl className={classes.nameField} component='fieldset'>
                <TextField
                  fullWidth
                  autoComplete="off"
                  variant="outlined"
                  type={showOldPassword ? 'text' : 'password'}
                  color="secondary"
                  value={form.old_password}
                  onChange={(e) => {
                    setForm(prev => ({
                      ...prev,
                      old_password: e.target.value
                    }));
                    setIsFilled(prev => ({
                      ...prev,
                      old_password: e.target.value.length === 0 ? false : true
                    }));
                    setPasswordError(false);
                    e.persist();
                  }}
                  error={!!changePasswordOptions.error.old_password || !isFilled.old_password}
                  helperText={!isFilled.old_password ? 'This field cannot be blank' : changePasswordOptions.error.old_password ? changePasswordOptions.error.old_password[0] : ' '}
                  InputProps={{
                    autoComplete: 'new-password',
                    endAdornment:
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setShowOldPassword(prev => !prev)}
                          onMouseDown={(event: React.MouseEvent<HTMLButtonElement>) => event.preventDefault()}
                        >
                          {showOldPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                  }}
                />
              </FormControl>
            }
          />
          <SettingsInnerGridLayout
            title="New Password"
            buffer
            component={
              <FormControl className={classes.nameField} component='fieldset'>
                <TextField
                  fullWidth
                  autoComplete="off"
                  variant="outlined"
                  type={showNewPassword ? 'text' : 'password'}
                  color="secondary"
                  value={form.new_password}
                  onChange={(e) => {
                    setForm(prev => ({
                      ...prev,
                      new_password: e.target.value
                    }));
                    setIsFilled(prev => ({
                      ...prev,
                      new_password: e.target.value.length === 0 ? false : true
                    }));
                    setPasswordError(false);
                    e.persist();
                  }}
                  error={!!changePasswordOptions.error.new_password || !isFilled.new_password}
                  helperText={!isFilled.new_password ? 'This field cannot be blank' : changePasswordOptions.error.new_password ? changePasswordOptions.error.new_password[0] : ' '}
                  InputProps={{
                    autoComplete: 'new-password',
                    endAdornment:
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setShowNewPassword(prev => !prev)}
                          onMouseDown={(event: React.MouseEvent<HTMLButtonElement>) => event.preventDefault()}
                        >
                          {showNewPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                  }}
                />
              </FormControl>
            }
          />
          <SettingsInnerGridLayout
            title="Confirm New Password"
            buffer
            component={
              <FormControl className={classes.nameField} component='fieldset'>
                <TextField
                  fullWidth
                  autoComplete="off"
                  variant="outlined"
                  type={showReNewPassword ? 'text' : 'password'}
                  color="secondary"
                  onChange={(e) => {
                    setForm(prev => ({
                      ...prev,
                      re_new_password: e.target.value
                    }));
                    setIsFilled(prev => ({
                      ...prev,
                      re_new_password: e.target.value.length === 0 ? false : true
                    }));
                    setPasswordError(false);
                    e.persist();
                  }}
                  error={passwordError || !isFilled.re_new_password}
                  helperText={!isFilled.re_new_password ? 'This field cannot be blank' : passwordError ? 'Passwords do not match' : ' '}
                  InputProps={{
                    autoComplete: 'new-password',
                    endAdornment:
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setShowReNewPassword(prev => !prev)}
                          onMouseDown={(event: React.MouseEvent<HTMLButtonElement>) => event.preventDefault()}
                        >
                          {showReNewPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                  }}
                />
              </FormControl>
            }
          />
          <Grid item xs={12} className={classes.saveButton}>
            <Button
              variant="contained"
              onClick={handleSubmit}
              color="primary"
              disabled={!form.old_password || !form.new_password || !form.re_new_password}
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </CardContent>
      <ResponseSnackbar message={message} setMessage={setMessage} severity='success' />
    </Card>
  )
}

export default ChangePasswordCard
