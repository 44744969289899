import React, { Fragment } from 'react'
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    marginBottom: 15,
    display: 'flex',
    alignItems: 'center'
  },
  buffer: {
    height: 19,
  },
  component: {
    textAlign: 'justify',
    marginBottom: 15,
    display: 'flex',
    alignItems: 'center'
  },
  textJustify: {
    textAlign: 'justify',
  },
  fineText: {
    fontSize: 9,
    color: theme.palette.text.disabled
  },
  '@media only screen and (max-width: 960px)': {
    title: {
      marginBottom: 30,
    },
    component: {
      marginBottom: 30,
    },
  },
}));

interface SettingsInnerGridLayoutProps {
  title: string | React.ReactNode;
  subtitle?: string;
  component?: React.ReactNode;
  buffer?: boolean;
}

const SettingsInnerGridLayout: React.FC<SettingsInnerGridLayoutProps> = ({ title, subtitle, component, buffer }: SettingsInnerGridLayoutProps) => {
  const classes = useStyles();

  return (
    <Fragment>
      <Grid item xs={1} />
      {component ? (
        <Fragment>
          <Grid item xs={5} className={classes.title}>
            <div>
              <Typography variant="subtitle1" component="span">{title}{buffer && <div className={classes.buffer} />}</Typography>
              {subtitle && <Typography variant="caption" component="p" className={classes.fineText}>{subtitle}</Typography>}
            </div>
          </Grid>
          <Grid item xs={5} className={classes.component}>
            {component}
          </Grid>
        </Fragment>
      ) : (
          <Fragment>
            <Grid item xs={10} className={classes.textJustify}>
              <Typography variant="body1" component="span">{title}{buffer && <div className={classes.buffer} />}</Typography>
            </Grid>
          </Fragment>
        )}
      <Grid item xs={1} />
    </Fragment>
  )
}

export default SettingsInnerGridLayout
