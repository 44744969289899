import React, { Fragment, useEffect, useState } from 'react';
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import { DialogFeatures, EditSnippetDetails, GenericResponseLoadState, initialDialogFeatures, initialGenericResponse, Options, Snippet } from '../../@types';
import { Avatar, Button, Card, CardContent, CardHeader, Grid, Typography } from '@material-ui/core';
import { ReportProblemRounded, Save } from '@material-ui/icons';
import AceEditor from 'react-ace';
import 'ace-builds/webpack-resolver';
import 'ace-builds/src-noconflict/ext-language_tools';
import LoadingAnimation from '../utility/LoadingAnimation';
import { getModeIcon, useCallApi } from '../../@utils';
import DialogAlert from '../utility/DialogAlert';
import { vw } from '../../@constants';
import { Skeleton } from '@material-ui/lab';
import ResponseSnackbar from '../utility/ResponseSnackbar';
import EditSnippetDetailsForm from '../forms/EditSnippetDetailsForm';
import SnippetMoreOptionsButton from '../buttons/SnippetMoreOptionsButton';

const useStyles = makeStyles((theme: Theme) => ({
  codeEditorCard: {
    height: '100%',
    '& .MuiGrid-root': {
      height: '100%',
      '& .MuiCard-root': {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        '& .MuiCardHeader-action': {
          marginTop: 0,
        },
        '& .MuiCardHeader-root': {
          wordBreak: 'break-word',
          paddingBottom: 12,
        },
        '& .MuiCardHeader-content': {
          overflow: 'hidden',
        },
        '& .MuiCardHeader-title': {
          '& #titleSkeleton': {
            marginBottom: 6,
          }
        },
        '& .MuiCardContent-root': {
          flex: '1 1 1px',
          overflow: 'scroll',
          paddingTop: 0,
          marginTop: 16
        },
      },
    },
  },
  saveButton: {
    marginLeft: 24,
    marginRight: 12,
  },
  emptyContainer: {
    height: '100%',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  mobileView: {
    height: 600,
    '& #actions': {
      marginTop: 20,
    },
  },
  mobileViewHeader: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 20,
    '& #headerContent': {
      flex: '1 1 auto',
      textAlign: 'left',
      wordBreak: 'break-word',
    },
    '& #headerActions': {
      display: 'flex',
      justifyContent: 'flex-end',
    },
  },
  buffer: {
    height: 'calc(100% - 126px)',
    width: '100%',
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    alignItem: 'center',
  },
  mobileButtonGroup: {
    display: 'flex',
    justifyContent: 'space-between',
    '& > * ': {
      boxShadow: 'none',
      width: '48%',
    },
  },
}));

interface CodeEditorCardProps {
  userID: number;
  isLoading: boolean;
  snippetView: Snippet;
  editingFlag: boolean;
  setEditingFlag: React.Dispatch<React.SetStateAction<boolean>>;
  triggerClose?: () => void;
}

const CodeEditorCard: React.FC<CodeEditorCardProps> = ({ userID, isLoading, snippetView, editingFlag, setEditingFlag, triggerClose }: CodeEditorCardProps) => {
  const classes = useStyles();
  const theme = useTheme();

  // Snackbar Handle Success for Edit and Delete
  const [message, setMessage] = useState<string>('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'info' | 'error'>('success');

  // Handle Edit Snippet
  const [form, setForm] = useState<EditSnippetDetails>({
    snippet_name: snippetView.snippet_name,
    snippet_content: snippetView.snippet_content,
    programming_language: snippetView.programming_language,
    theme: snippetView.theme,
    collection_id: snippetView.collection_id,
  })
  const handleEditSnippetDetails = () => {
    setDialogFeatures({
      open: true,
      title: 'Edit Details',
      width: 600,
      color: `${theme.palette.primary.main}80`,
      customBodyActions: <EditSnippetDetailsForm
        userID={userID}
        triggerClose={() => {
          setDialogFeatures(initialDialogFeatures)
        }}
        setMessage={setMessage}
        setSnackbarSeverity={setSnackbarSeverity}
        snippetView={snippetView}
        form={form}
        setForm={setForm}
        setEditingFlag={setEditingFlag}
      />,
    });
  };

  const [editSnippetLoadState, editSnippetOptions]: [GenericResponseLoadState, Options] = useCallApi(
    `snippet/edit/${snippetView.id}/`,
    initialGenericResponse,
    'PUT',
    false,
    'PRIVATE'
  );

  const handleSave = () => {
    editSnippetOptions.setData(form)
  };

  useEffect(() => {
    if (editSnippetLoadState.data.success) {
      setEditingFlag(false);
      setSnackbarSeverity('success');
      setMessage('Changes Successfully Saved!');
      window.location.reload();
    } else {
      return
    }
  }, [editSnippetLoadState.data.success, setEditingFlag]);

  // Set edit snippet form to defaults
  useEffect(() => {
    setForm({
      snippet_name: snippetView.snippet_name,
      snippet_content: snippetView.snippet_content,
      programming_language: snippetView.programming_language,
      theme: snippetView.theme,
      collection_id: snippetView.collection_id,
    })
  }, [snippetView.snippet_name, snippetView.snippet_content, snippetView.programming_language, snippetView.theme, snippetView.collection_id]);

  // Handler for repeated snippet name
  useEffect(() => {
    if (editSnippetOptions.error.error) {
      setSnackbarSeverity('error');
      setMessage('Snippet Name already exists! Please choose another name.');
    }
  }, [editSnippetOptions.error.error]);

  // Handle Delete Snippet
  const [deleteSnippetLoadState, deleteSnippetOptions]: [GenericResponseLoadState, Options] = useCallApi(
    `snippet/delete/${snippetView.id}/`,
    initialGenericResponse,
    'DELETE',
    false,
    'PRIVATE'
  );

  const [dialogFeatures, setDialogFeatures] = useState<DialogFeatures>(initialDialogFeatures);
  const handleDelete = () => {
    setDialogFeatures({
      open: true,
      title: 'Are you sure? This action cannot be undone',
      icon: <ReportProblemRounded />,
      color: `${theme.palette.error.main}80`,
      message: 'Snippet will be permanently deleted',
      primaryAction: {
        buttonText: 'Yes, Delete',
        buttonFunction: () => {
          deleteSnippetOptions.setShouldFetch(true);
        }
      },
      cancelButton: true,
    });
  };

  useEffect(() => {
    if (deleteSnippetLoadState.data.success) {
      setSnackbarSeverity('success');
      setMessage('Snippet Successfully Deleted!');
      window.location.reload();
    } else {
      return
    }
  }, [deleteSnippetLoadState]);

  // This prevents "theme-theme.js:1 Uncaught SyntaxError: Unexpected token '<'" error in ace-editor
  // Probably due to programming_language, theme, snippet_content not loading yet before mounting
  const [editorLoading, setEditorLoading] = useState<boolean>(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setEditorLoading(false);
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <Grid container spacing={0} className={classes.codeEditorCard}>
      <Grid item xs={12}>
        {vw >= 960 ? (
          <Card>
            <CardHeader
              avatar={snippetView.id ? (
                <Avatar
                  variant="square"
                  src={getModeIcon(form.programming_language ? form.programming_language : snippetView.programming_language)}
                  alt={form.programming_language ? form.programming_language : snippetView.programming_language}
                />
              ) : (
                  <Fragment>
                    {isLoading &&
                      <Skeleton variant="circle" animation="wave" height={40} width={40} />
                    }
                  </Fragment>
                )}
              title={snippetView.id ? (
                <Typography variant="h5" component="span">{form.snippet_name ? form.snippet_name : snippetView.snippet_name}</Typography>
              ) : (
                  <Fragment>
                    {isLoading &&
                      <Skeleton animation="wave" height={10} width="80%" id="titleSkeleton" />
                    }
                  </Fragment>
                )}
              subheader={snippetView.id ? (
                "Code Editor"
              ) : (
                  <Fragment>
                    {isLoading &&
                      <Skeleton animation="wave" height={10} width="40%" />
                    }
                  </Fragment>
                )}
              action={snippetView.id ? (
                <Fragment>
                  <Button
                    className={classes.saveButton}
                    variant="contained"
                    onClick={handleSave}
                    color="primary"
                    startIcon={<Save />}
                    disabled={
                      form.snippet_name === snippetView.snippet_name &&
                      form.snippet_content === snippetView.snippet_content &&
                      form.programming_language === snippetView.programming_language &&
                      form.theme === snippetView.theme &&
                      form.collection_id === snippetView.collection_id
                    }
                  >
                    Save
                    </Button>
                  <SnippetMoreOptionsButton handleEditSnippetDetails={handleEditSnippetDetails} handleDelete={handleDelete} />
                </Fragment>
              ) : (
                  null
                )}
            />
            <CardContent>
              {snippetView.id ? (
                <AceEditor
                  mode={form.programming_language ? form.programming_language : snippetView.programming_language}
                  theme={form.theme ? form.theme : snippetView.theme}
                  name="Editor"
                  onChange={(newValue: string) => {
                    setForm(prev => ({
                      ...prev,
                      snippet_content: newValue
                    }));
                    newValue === snippetView.snippet_content ? setEditingFlag(false) : setEditingFlag(true);
                  }}
                  fontSize={12}
                  showPrintMargin={true}
                  showGutter={true}
                  highlightActiveLine={true}
                  value={form.snippet_content ? form.snippet_content : snippetView.snippet_content}
                  width="100%"
                  height="100%"
                  wrapEnabled={true}
                  setOptions={{
                    enableBasicAutocompletion: true,
                    enableLiveAutocompletion: true,
                    enableSnippets: true,
                    showLineNumbers: true,
                    tabSize: 4,
                  }} />
              ) : (
                  <div className={classes.emptyContainer}>
                    {isLoading ? (
                      <LoadingAnimation />
                    ) : (
                        <Typography variant="h6" component="h6">No snippets created</Typography>
                      )}
                  </div>
                )}
            </CardContent>
          </Card>
        ) : (
            <div className={classes.mobileView}>
              <div className={classes.mobileViewHeader}>
                <div id="headerContent">
                  <Typography variant="h6" component="h6">{form.snippet_name ? form.snippet_name : snippetView.snippet_name}</Typography>
                  <Typography variant="body2" component="p">Code Editor</Typography>
                </div>
                <div id="headerActions">
                  <SnippetMoreOptionsButton handleEditSnippetDetails={handleEditSnippetDetails} handleDelete={handleDelete} />
                </div>
              </div>
              {editorLoading ? (
                <div className={classes.buffer}>
                  <LoadingAnimation />
                </div>
              ) : (
                  <AceEditor
                    mode={form.programming_language ? form.programming_language : snippetView.programming_language}
                    theme={form.theme ? form.theme : snippetView.theme}
                    name="Editor"
                    onChange={(newValue: string) => setForm(prev => ({
                      ...prev,
                      snippet_content: newValue
                    }))}
                    fontSize={11}
                    showPrintMargin={true}
                    showGutter={false}
                    highlightActiveLine={true}
                    value={form.snippet_content ? form.snippet_content : snippetView.snippet_content}
                    width="100%"
                    height="calc(100% - 126px)"
                    wrapEnabled={true}
                    setOptions={{
                      enableBasicAutocompletion: true,
                      enableLiveAutocompletion: true,
                      enableSnippets: true,
                      showLineNumbers: false,
                      tabSize: 4,
                    }}
                  />
                )}
              <div id="actions" className={classes.mobileButtonGroup}>
                <Button variant='contained' onClick={triggerClose}>
                  Cancel
                </Button>
                <Button
                  className={classes.saveButton}
                  variant="contained"
                  color="primary"
                  onClick={handleSave}
                  startIcon={<Save />}
                  disabled={
                    form.snippet_name === snippetView.snippet_name &&
                    form.snippet_content === snippetView.snippet_content &&
                    form.programming_language === snippetView.programming_language &&
                    form.theme === snippetView.theme &&
                    form.collection_id === snippetView.collection_id
                  }
                >
                  Save
                </Button>
              </div>
            </div>
          )}
      </Grid>
      <DialogAlert dialogFeatures={dialogFeatures} setDialogFeatures={setDialogFeatures} />
      <ResponseSnackbar message={message} setMessage={setMessage} severity={snackbarSeverity} />
    </Grid>
  )
}

export default CodeEditorCard
