import React, { useContext, useEffect, useState } from 'react';
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import { Button, FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select, TextField } from '@material-ui/core';
import AceEditor from 'react-ace';
import { Collection, GenericResponseLoadState, initialGenericResponse, CollectionListLoadState, initialCollectionList, NewSnippet, NewSnippetIsFilled, Options, DialogFeatures, initialDialogFeatures } from '../../@types';
import { getDefaultContent, useCallApi } from '../../@utils';
import 'ace-builds/webpack-resolver';
import 'ace-builds/src-noconflict/ext-language_tools';
import { Add, ArrowForward, Info } from '@material-ui/icons';
import NewCollectionForm from './NewCollectionForm';
import DialogAlert from '../utility/DialogAlert';
import { useLocation } from 'react-router-dom';
import { MODES, THEMES, vh, vw } from '../../@constants';
import { UserDefaultsContext } from '../../context/UserDefaultsContext';
import LoadingAnimation from '../utility/LoadingAnimation';

const useStyles = makeStyles((theme: Theme) => ({
  nameField: {
    width: '100%',
    marginBottom: 10,
  },
  collectionSelect: {
    minWidth: 200,
    width: '100%'
  },
  newCollectionButton: {
    width: '100%',
    marginBottom: 10
  },
  noCollections: {
    justifyContent: 'center',
  },
  formControl: {
    minWidth: 120,
    width: '100%',
    marginBottom: 12,
    '& .ace_scrollbar': {
      display: 'none'
    }
  },
  editor: {
    marginBottom: 10,
  },
  buttonGroup: {
    display: 'flex',
    justifyContent: 'space-between',
    '& > * ': {
      boxShadow: 'none',
      width: '48%',
    },
  },
  menuPaper: {
    maxHeight: 315,
  },
  buffer: {
    height: 'calc(80vh - 365px)',
    width: '100%',
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    alignItem: 'center',
  },
  '@media only screen and (max-height: 600px)': {
    buffer: {
      height: 200,
    }
  },
  '@media only screen and (max-width: 600px)': {
    buffer: {
      height: 200,
    }
  },
}));

interface NewSnippetFormProps {
  userID: number;
  triggerClose: () => void;
  setMessage: React.Dispatch<React.SetStateAction<string>>;
  defaultCollectionID?: number;
};

const NewSnippetForm: React.FC<NewSnippetFormProps> = ({ userID, triggerClose, setMessage, defaultCollectionID }: NewSnippetFormProps) => {
  const classes = useStyles();
  const theme = useTheme();
  const location = useLocation();

  const [collectionListLoadState, collectionListOptions]: [CollectionListLoadState, Options] = useCallApi(
    `collection/${userID}/`,
    initialCollectionList,
    'GET',
    true,
    'PRIVATE'
  );

  const [dialogFeatures, setDialogFeatures] = useState<DialogFeatures>(initialDialogFeatures);
  const handleNewCollection = () => {
    setDialogFeatures({
      open: true,
      title: 'Add Collection',
      color: `${theme.palette.primary.main}80`,
      customBodyActions: <NewCollectionForm
        userID={userID}
        triggerClose={() => {
          setDialogFeatures(initialDialogFeatures)
          triggerClose();
        }}
        setMessage={setMessage}
      />,
    });
  };

  const { defaultMode, defaultTheme } = useContext(UserDefaultsContext);

  const [form, setForm] = useState<NewSnippet>({
    snippet_name: '',
    snippet_content: '',
    programming_language: defaultMode,
    theme: '', // Left empty, change after render to prevent react-ace editor text wrapping bug. See below useEffect
    collection_id: defaultCollectionID ? defaultCollectionID : '',
  });

  const [isFilled, setIsFilled] = useState<NewSnippetIsFilled>({
    snippet_name: true,
    snippet_content: true,
    programming_language: true,
    theme: true,
    collection_id: true
  });

  const onChange = (newValue: string) => {
    setForm(prev => ({
      ...prev,
      snippet_content: newValue
    }));
  };

  const [newSnippetLoadState, newSnippetOptions]: [GenericResponseLoadState, Options] = useCallApi(
    'snippet/create/',
    initialGenericResponse,
    'POST',
    false,
    'PRIVATE'
  );

  const handleSubmit = () => {
    //Show Errors
    for (let [field, value] of Object.entries(form)) {
      setIsFilled(prev => ({
        ...prev,
        [field]: !value ? false : true
      }));
    };

    if (form.snippet_content === '') {
      setDialogFeatures({
        open: true,
        title: '',
        icon: <Info />,
        color: `${theme.palette.secondary.main}80`,
        message: 'Please input some code in the code editor!',
        primaryAction: {
          buttonText: 'Ok',
          buttonFunction: () => {
            setDialogFeatures(initialDialogFeatures);
          }
        },
      })
    }

    // Validation
    if (!Object.values(form).includes('')) {
      newSnippetOptions.setData({
        snippet_name: form.snippet_name,
        snippet_content: form.snippet_content,
        programming_language: form.programming_language,
        theme: form.theme,
        collection_id: form.collection_id,
        user_id: userID,
      })
    };
  };

  useEffect(() => {
    if (newSnippetLoadState.data.success) {
      setMessage('Snippet Successfully Created!');
      if (location.pathname === '/dashboard') window.location.reload();
    }

    // Delay on closing dialog to prevent memory leak on unmount
    const delay = setInterval(() => {
      if (newSnippetLoadState.data.success) triggerClose();
    }, 10)
    return () => clearInterval(delay);
  }, [newSnippetLoadState.data.success, triggerClose, setMessage, location.pathname]);

  useEffect(() => {
    setForm(prev => ({
      ...prev,
      theme: defaultTheme
    })); // prevents react-ace editor text wrapping bug on render
  }, [defaultTheme]);

  // This prevents "theme-theme.js:1 Uncaught SyntaxError: Unexpected token '<'" error in ace-editor
  // Probably due to programming_language, theme, snippet_content not loading yet before mounting
  const [editorLoading, setEditorLoading] = useState<boolean>(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setEditorLoading(false);
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  // Handler for repeated snippet name
  const [snippetNameError, setSnippetNameError] = useState<boolean>(false);

  useEffect(() => {
    if (newSnippetOptions.error.error) {
      setSnippetNameError(true);
    }
  }, [newSnippetOptions.error.error]);

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} sm={6}>
        <FormControl variant="outlined" className={classes.collectionSelect}>
          <InputLabel id="collection-select" color="secondary">Select Collection</InputLabel>
          <Select
            labelId="collection-select"
            value={form.collection_id}
            color="secondary"
            onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
              setForm(prev => ({
                ...prev,
                collection_id: event.target.value as number,
              }));
            }}
            label="Select Collection"
            error={!isFilled.collection_id}
            disabled={!!defaultCollectionID}
            MenuProps={{ classes: { paper: classes.menuPaper } }}
          >
            <Button
              className={classes.newCollectionButton}
              onClick={handleNewCollection}
              startIcon={<Add />}
            >
              New Collection
            </Button>
            {collectionListOptions.error.error &&
              <MenuItem disabled value='' className={classes.noCollections}><em>No Collections</em></MenuItem>
            }
            {collectionListLoadState.data?.map((collection: Collection, index: number) => {
              return <MenuItem key={collection.id} value={collection.id}>{collection.collection_name}</MenuItem>
            })}
            {collectionListLoadState.isLoading && <LoadingAnimation />}
          </Select>
          <FormHelperText error={!isFilled.collection_id}>{isFilled.collection_id ? ' ' : 'Please select a collection'}</FormHelperText>
        </FormControl>
      </Grid>
      <Grid item xs={6} sm={3}>
        <FormControl variant="outlined" className={classes.formControl}>
          <InputLabel id="theme-select" color="secondary">Theme</InputLabel>
          <Select
            labelId="theme-select"
            value={form.theme}
            color="secondary"
            onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
              setForm(prev => ({
                ...prev,
                theme: event.target.value as string,
              }));
            }}
            label="Theme"
            MenuProps={{ classes: { paper: classes.menuPaper } }}
          >
            {THEMES.map((theme) => {
              return <MenuItem key={theme.value} value={theme.value}>{theme.name}</MenuItem>
            })}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={6} sm={3}>
        <FormControl variant="outlined" className={classes.formControl}>
          <InputLabel id="language-select" color="secondary">Mode</InputLabel>
          <Select
            labelId="language-select"
            value={form.programming_language}
            color="secondary"
            onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
              setForm(prev => ({
                ...prev,
                programming_language: event.target.value as string,
              }));
            }}
            label="Mode"
            MenuProps={{ classes: { paper: classes.menuPaper } }}
          >
            {MODES.map((mode) => {
              return <MenuItem key={mode.value} value={mode.value}>{mode.name}</MenuItem>
            })}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <FormControl className={classes.nameField} component='fieldset'>
          <TextField
            fullWidth
            id="name"
            name="name"
            label='Snippet Name'
            autoComplete="off"
            variant="filled"
            color="secondary"
            inputProps={{ maxLength: 50 }}
            onChange={(e) => {
              setForm(prev => ({
                ...prev,
                snippet_name: e.target.value
              }));
              setIsFilled(prev => ({
                ...prev,
                snippet_name: e.target.value.length === 0 ? false : true
              }));
              setSnippetNameError(false);
              e.persist();
            }}
            error={snippetNameError || !isFilled.snippet_name}
            helperText={snippetNameError ? 'Snippet Name already exists!' : isFilled.snippet_name ? `${form.snippet_name.length}/50` : 'Snippet Name cannot be blank'}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} className={classes.editor}>
        {editorLoading ? (
          <div className={classes.buffer}>
            <LoadingAnimation />
          </div>
        ) : (
            <AceEditor
              mode={form.programming_language}
              theme={form.theme}
              name="Editor"
              onChange={onChange}
              fontSize={14}
              showPrintMargin={true}
              showGutter={true}
              highlightActiveLine={true}
              value={form.snippet_content}
              height={vh <= 600 || vw <= 600 ? "200px" : "calc(80vh - 365px)"}
              placeholder={getDefaultContent(form.programming_language)}
              width='100%'
              wrapEnabled={true}
              setOptions={{
                enableBasicAutocompletion: true,
                enableLiveAutocompletion: true,
                enableSnippets: true,
                showLineNumbers: true,
                tabSize: 4,
              }} />
          )}
      </Grid>
      <Grid item xs={12} className={classes.buttonGroup}>
        <Button variant='contained' onClick={triggerClose}>
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          endIcon={<ArrowForward />}
        >
          Add!
        </Button>
      </Grid>
      <DialogAlert dialogFeatures={dialogFeatures} setDialogFeatures={setDialogFeatures} />
    </Grid>
  )
}

export default NewSnippetForm
