export interface GenericResponseInterface {
    error: boolean;
    success: boolean;
    message: string;

    // Registration Data Types
    username?: string;

    // Login Data Types
    token?: string;
    user_id?: number;
};

export interface GenericResponseLoadStateInterface {
    isLoading: boolean;
    isError: boolean;
    data: GenericResponseInterface;
};

export const initialGenericResponse: GenericResponseLoadStateInterface['data'] = {
    error: false,
    success: false,
    message: '',
};