export const THEMES = [
  {
    name: 'Terminal',
    value: 'terminal'
  },
  {
    name: 'Monokai',
    value: 'monokai'
  },
  {
    name: 'GitHub',
    value: 'github'
  },
  {
    name: 'Ambiance',
    value: 'ambiance'
  },
  {
    name: 'Chaos',
    value: 'chaos'
  },
  {
    name: 'Chrome',
    value: 'chrome'
  },
  {
    name: 'Clouds',
    value: 'clouds'
  },
  {
    name: 'Clouds Midnight',
    value: 'clouds_midnight'
  },
  {
    name: 'Cobalt',
    value: 'cobalt'
  },
  {
    name: 'Crimson',
    value: 'crimson_editor'
  },
  {
    name: 'Dawn',
    value: 'dawn'
  },
  {
    name: 'Dracula',
    value: 'dracula'
  },

  {
    name: 'Dreamweaver',
    value: 'dreamweaver'
  },
  {
    name: 'Eclipse',
    value: 'eclipse'
  },
  {
    name: 'Gob',
    value: 'gob'
  },
  {
    name: 'Nord Dark',
    value: 'nord_dark'
  },
  {
    name: 'Solarized Dark',
    value: 'solarized_dark'
  },
  {
    name: 'Solarized Light',
    value: 'solarized_light'
  },
  {
    name: 'SQL Server',
    value: 'sqlserver'
  },
  {
    name: 'Tomorrow',
    value: 'tomorrow'
  },
  {
    name: 'Tomorrow Night',
    value: 'tomorrow_night'
  },
  {
    name: 'Twilight',
    value: 'twilight'
  },
  {
    name: 'Xcode',
    value: 'xcode'
  },
]