import React, { useState, useEffect, useCallback } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Grid, FormControl, TextField, InputAdornment, IconButton, Button, Backdrop, CircularProgress } from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { useCallApi } from '../../@utils';
import { GenericResponseLoadState, initialGenericResponse, Options, Login, LoginIsFilled } from '../../@types';
import { useHistory, useLocation } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) => ({
  formfield: {
    width: '100%',
    marginBottom: 20,
    '& .MuiIconButton-root': {
      color: theme.palette.text.secondary,
      padding: 8,
    },
  },
  loginButton: {
    '& .MuiButton-root': {
      boxShadow: 'none',
      width: '100%',
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

interface LoginFormProps {
  triggerClose: () => void;
}

const LoginForm: React.FC<LoginFormProps> = ({ triggerClose }: LoginFormProps) => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();

  const [loginLoadState, loginOptions]: [GenericResponseLoadState, Options] = useCallApi(
    'authentication/login/',
    initialGenericResponse,
    'POST',
    false,
    'PUBLIC'
  );
  const postLogin = loginOptions.setData

  const [form, setForm] = useState<Login>({
    username: '',
    password: ''
  });

  const [isFilled, setIsFilled] = useState<LoginIsFilled>({
    username: true,
    password: true
  });

  const [showPassword, setShowPassword] = useState<boolean>(false);

  const handleSubmit = useCallback(() => {
    //Show Errors
    for (let [field, value] of Object.entries(form)) {
      setIsFilled(prev => ({
        ...prev,
        [field]: !value ? false : true
      }));
    };

    // Validation
    if (!Object.values(form).includes('')) {
      postLogin(form);
    };
  }, [form, postLogin]);

  useEffect(() => {
    if (!!loginLoadState.data.token && loginLoadState.data.user_id) {
      localStorage.setItem('token', loginLoadState.data.token);
      history.push('/dashboard');
    }

    // Delay on closing dialog to prevent memory leak on unmount
    const delay = setInterval(() => {
      if (location.pathname === '/dashboard') triggerClose();
    }, 10)
    return () => clearInterval(delay);
  }, [loginLoadState.data, history, triggerClose, location.pathname]);

  useEffect(() => {
    const listener = (event: any) => {
      if (event.code === 'Enter' || event.code === 'NumpadEnter') {
        handleSubmit();
      }
    }
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener)
    }
  }, [handleSubmit]);

  return (
    <Grid container spacing={0}>
      <Grid item xs={12}>
        <form onSubmit={handleSubmit}>
          <FormControl className={classes.formfield} component="fieldset">
            <TextField
              fullWidth
              id="username"
              name="username"
              label='Username'
              autoComplete="off"
              variant="filled"
              color="secondary"
              onChange={(e) => {
                setForm(prev => ({
                  ...prev,
                  username: e.target.value
                }));
                setIsFilled(prev => ({
                  ...prev,
                  username: e.target.value.length === 0 ? false : true
                }));
                e.persist();
              }}
              error={!!loginOptions.error.non_field_errors || !isFilled.username}
              helperText={!isFilled.username ? 'This field cannot be blank' : ' '}
            />
          </FormControl>
          <FormControl className={classes.formfield} component="fieldset">
            <TextField
              fullWidth
              id="password"
              name="password"
              label="Password"
              autoComplete="off"
              variant="filled"
              type={showPassword ? 'text' : 'password'}
              color="secondary"
              onChange={(e) => {
                setForm(prev => ({
                  ...prev,
                  password: e.target.value
                }));
                setIsFilled(prev => ({
                  ...prev,
                  password: e.target.value.length === 0 ? false : true
                }));
                e.persist();
              }}
              error={!!loginOptions.error.non_field_errors || !isFilled.password}
              helperText={!isFilled.password ? 'This field cannot be blank' : loginOptions.error.non_field_errors ? loginOptions.error.non_field_errors : ' '}
              InputProps={{
                endAdornment:
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword(prev => !prev)}
                      onMouseDown={(event: React.MouseEvent<HTMLButtonElement>) => event.preventDefault()}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
              }}
            />
          </FormControl>
        </form>
      </Grid>
      <Grid item xs={12} className={classes.loginButton}>
        <Button variant="contained" color="primary" onClick={handleSubmit}>
          Login
        </Button>
      </Grid>
      <Backdrop className={classes.backdrop} open={loginLoadState.isLoading}>
        <CircularProgress color="secondary" />
      </Backdrop>
    </Grid>
  )
}

export default LoginForm
