import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Button, Card, CardContent, CardHeader, Grid, Link } from '@material-ui/core';
import PatreonLogo from '../../assets/images/patreon/Digital-Patreon-Logo_White.png';
import PatreonWordmark from '../../assets/images/patreon/Digital-Patreon-Wordmark_Black.png';
import CreatorsSocialButtons from '../buttons/CreatorsSocialButtons';
import SettingsInnerGridLayout from '../layouts/SettingsInnerGridLayout';

const useStyles = makeStyles((theme: Theme) => ({
  patreonButton: {
    textAlign: 'center',
    margin: 30,
    '& .MuiButton-root': {
      backgroundColor: '#FF424D',
      width: 180,
    },
  },
}));

const DonationCard: React.FC = () => {
  const classes = useStyles();

  return (
    <Card>
      <CardHeader
        title="Donate!"
      />
      <CardContent>
        <Grid container spacing={0}>
          <SettingsInnerGridLayout
            title="Become a patron and play a part in helping me cover some of the server and maintenance costs. Your donations will go long way, thank you!"
          />
          <Grid item xs={12} className={classes.patreonButton}>
            <Link href='https://www.patreon.com/leonardtng' target='_blank'>
              <Button
                startIcon={<img src={PatreonLogo} alt="Patreon Logo" height="30px" width="30px" />}
              >
                <img src={PatreonWordmark} alt="Patreon" height="22px" width="108px" />
              </Button>
            </Link>
          </Grid>
          <SettingsInnerGridLayout
            title="Also, you can follow me on my social links here and we can get in touch!"
          />
          <Grid item xs={12}>
            <CreatorsSocialButtons />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

export default DonationCard