import React, { useContext } from 'react';
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import { Card, CardContent, CardHeader, Grow, Typography } from '@material-ui/core';
import CanvasJSReact from '../../assets/packages/canvasjs-3.2/canvasjs.react';
import { getModeName } from '../../@utils';
import { DonutStats, DonutStatsLoadState } from '../../@types';
import { ThemeContext } from '../../context/ThemeContext';


const useStyles = makeStyles((theme: Theme) => ({
  filler: {
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    height: 20,
    width: '101%',
    transform: 'translate(-1px, -30px)'
  },
  buffer: {
    height: 400,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
  },
}));

interface DonutCardProps {
  donutStatsLoadState: DonutStatsLoadState;
}

const DonutCard: React.FC<DonutCardProps> = ({ donutStatsLoadState }: DonutCardProps) => {
  const classes = useStyles();
  const theme = useTheme();
  const { darkMode } = useContext(ThemeContext);
  const CanvasJSChart = CanvasJSReact.CanvasJSChart;
  // const CanvasJS = CanvasJSReact.CanvasJS;

  // CanvasJS.addColorSet("custom",
  //               [//colorSet Array
  //               "#C0392B",                                                
  //               "#2E86C1",
  //               "#F39C12",
  //               "#16A085",
  //               "#512E5F",
  //               "#00FFCC",
  //               "#00FF00",
  //               ]);

  const options = {
    animationEnabled: true,
    colorSet: "custom",
    backgroundColor: theme.palette.background.paper,
    theme: darkMode ? 'dark1' : 'light1',
    data: [{
      type: "doughnut",
      indexLabel: "{name}: {y}",
      radius: "80%",
      innerRadius: "60%",
      indexLabelFontSize: 12,
      indexLabelFontFamily: "Gilroy-ExtraBold",
      dataPoints: donutStatsLoadState.data.map((stat: DonutStats) => {
        return { name: getModeName(stat.name), y: stat.y }
      })
    }]
  }

  return (
    <Grow in timeout={1500}>
      <Card>
        <CardHeader
          title="Language Breakdown"
        />
        <CardContent>
          {donutStatsLoadState.data.length === 0 ? (
            <div className={classes.buffer}>
              <Typography variant='h6' component='p' gutterBottom><em>You have no snippets</em></Typography>
              <Typography variant='body2' component='p' gutterBottom>(Create some snippets to see your language breakdown!)</Typography>
            </div>
          ) : (
              <CanvasJSChart options={options} />
            )}
        </CardContent>
        <div className={classes.filler} />
      </Card>
    </Grow>
  )
}

export default DonutCard