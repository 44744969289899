import { useState, useReducer, useEffect } from 'react';
import { API_CONFIG } from '../../@constants';
import { Options, ErrorObject, initialErrorObject, State, Action } from '../../@types';
import axios, { Canceler, AxiosRequestConfig } from 'axios';
// import { useParams } from 'react-router-dom';

const dataFetchReducer = (state: State, action: Action) => {
  // console.log('1: state', state)
  // console.log('2: action', action)
  switch (action.type) {
    case 'FETCH_INIT':
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    case 'FETCH_SUCCESS':
      return {
        ...state,
        isLoading: false,
        isError: false,
        data: action.payload,
      };
    case 'FETCH_FAILURE':
      return {
        ...state,
        isLoading: false,
        isError: true,
      };
    default:
      throw new Error();
  }
};

const config: AxiosRequestConfig = API_CONFIG;

const useCallApi = (
  source: string,
  initialData: Action['payload'],
  method: "GET" | "POST" | "PUT" | "DELETE" | "PATCH",
  defaultFetch: boolean,
  authentication: 'PRIVATE' | 'PUBLIC',
  args?: string,
  input?: object,
): [State, Options] => {

  const [shouldFetch, setShouldFetch] = useState<boolean>(defaultFetch);
  const [queryParams, setQueryParams] = useState<string | undefined>(args);
  // const {  } = useParams(); Put params that change when page changes here

  // When internal routing changes or when defaultFetch param is changed, change shouldFetch state
  useEffect(() => {
    setShouldFetch(defaultFetch);
  }, [defaultFetch]); // Put params in dependency array

  // data only changes when POST PUT or PATCH, therefore default is false. So a separate useEffect is needed to handle that
  const [data, setData] = useState<object | undefined>(input);
  const [error, setError] = useState<ErrorObject>(initialErrorObject);
  const [status, setStatus] = useState<number | null>(null);

  // If data is set to a non undefined value, send the request
  useEffect(() => {
    if (data) setShouldFetch(true);
  }, [data]);

  // If query params change or is set to a non undefined value, send the request
  useEffect(() => {
    if (queryParams) setShouldFetch(true);
  }, [queryParams]);

  const [state, dispatch] = useReducer(dataFetchReducer, {
    isLoading: shouldFetch,
    isError: false,
    data: initialData,
  })

  useEffect(() => {
    if (authentication === 'PRIVATE' && shouldFetch) {
      config.headers = { ...config.headers, 'Authorization': `Token ${localStorage.getItem('token')}` };
    } else {
      delete config.headers['Authorization']
    }

    let cancel: Canceler
    config.method = method;
    config.url = config.baseURL + source + `${queryParams ? `${queryParams}/` : ''}`;
    // console.log('0: API', config.url)
    config.cancelToken = new axios.CancelToken(c => cancel = c);
    if (data) config.data = data;
    const fetchData = async () => {
      dispatch({ type: 'FETCH_INIT' });
      setStatus(null);
      setError(initialErrorObject);
      try {
        const result = await axios.request(config);
        dispatch({ type: 'FETCH_SUCCESS', payload: result.data ? result.data : { success: true } });
        setStatus(result.status)
      }
      catch (error) {
        if (axios.isCancel(error)) {
          // console.log('3: CANCEL', error);
          return
        }
        dispatch({ type: 'FETCH_FAILURE' });
        error.response ? setError(error.response.data) : setError(initialErrorObject);
        error.response ? setStatus(error.response.status) : setStatus(null);
      }
      // console.log('2', shouldFetch, method, sourceURL)
      setShouldFetch(false);
    }
    // console.log('1', shouldFetch, method, sourceURL)
    if (shouldFetch) { fetchData() };
    return () => cancel();
  }, [method, source, authentication, shouldFetch, queryParams, data, initialData]); // Put params in dependency array

  //when the dependencies of useEffect change, the useEffect hook will trigger again
  return [state, { setQueryParams: setQueryParams, setData: setData, setShouldFetch: setShouldFetch, error: error, status: status }];
}

export default useCallApi