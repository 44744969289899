import React, { useState, useEffect, Fragment } from 'react';
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import { AppBar, Toolbar, Typography, Button, Grid, Hidden, IconButton, Drawer, Backdrop, CircularProgress } from '@material-ui/core';
import { Menu, TimelineSharp } from '@material-ui/icons';
import { useHistory, useLocation } from 'react-router-dom';
import { DialogFeatures, initialDialogFeatures, GenericResponseLoadState, Options } from '../../@types';
import LoginForm from '../forms/LoginForm';
import DialogAlert from '../utility/DialogAlert';
import { useCallApi } from '../../@utils';
import { initialGenericResponse } from '../../@types/common/GenericResponse';
import StatsCardGroup from './StatsCardGroup';

const useStyles = makeStyles<Theme>((theme: Theme) => ({
  root: {
    flexGrow: 1,
    '& .MuiAppBar-root': {
      background: 'transparent',
      boxShadow: 'none',
    },
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  title: {
    textAlign: 'left',
    justifyContent: 'flex-start',
    '& .MuiButton-root': {
      marginLeft: 20,
      textTransform: 'none',
    },
  },
  buttonGroup: {
    textAlign: 'right',
    justifyContent: 'flex-end',
    color: theme.palette.text.primary,
    '& .MuiButton-root': {
      marginRight: 20,
    },
  },
  drawer: {
    paddingTop: 30,
    width: 500,
    overflowX: 'hidden',
    '& .MuiCard-root': {
      boxShadow: 'none',
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  '@media only screen and (max-width: 1280px)': {
    root: {
      '& .MuiAppBar-root': {
        background: theme.palette.background.paper,
        boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
      },
    },
  },
}));

interface NavBarProps {
  userID: number;
  mobileOpen: boolean;
  setMobileOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const NavBar: React.FC<NavBarProps> = ({ userID, mobileOpen, setMobileOpen }: NavBarProps) => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const theme = useTheme();

  const flag = !!location.pathname.includes('/dashboard');

  const [logoutLoadState, logoutOptions]: [GenericResponseLoadState, Options] = useCallApi(
    'authentication/logout/',
    initialGenericResponse,
    'POST',
    false,
    'PRIVATE'
  )
  const [dialogFeatures, setDialogFeatures] = useState<DialogFeatures>(initialDialogFeatures);

  const handleLogin = () => {
    setDialogFeatures({
      open: true,
      title: 'Welcome Back',
      color: `${theme.palette.primary.main}80`,
      customBodyActions: <LoginForm triggerClose={() => setDialogFeatures(initialDialogFeatures)} />,
    })
  };

  const handleLogout = () => {
    logoutOptions.setShouldFetch(true);
  };

  useEffect(() => {
    if (logoutLoadState.data.success || logoutLoadState.isError) {
      localStorage.removeItem('token')
      history.push('/');
    } else {
      return
    }
  }, [logoutLoadState.data, history, logoutLoadState.isError]);

  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);

  return (
    <div className={classes.root}>
      <AppBar position='fixed' className={classes.appBar}>
        <Toolbar>
          <Grid container spacing={0}>
            <Hidden smUp>
              {userID ? (
                <Grid item xs={2}>
                  <IconButton
                    color="default"
                    edge="start"
                    onClick={() => setMobileOpen(true)}
                  >
                    <Menu />
                  </IconButton>
                </Grid>
              ) : (
                  null
                )}
            </Hidden>
            <Grid item xs={4} sm={5} className={classes.title}>
              <Button color="inherit" onClick={() => history.push('/')}>
                <Typography variant="h5" component="h1" color="textPrimary">
                  CodeCache
                </Typography>
              </Button>
            </Grid>
          </Grid>
          <Grid item xs={7} className={classes.buttonGroup}>
            {flag ? (
              <Fragment>
                <Hidden xlUp xsDown>
                  <Button
                    color="secondary"
                    startIcon={<TimelineSharp />}
                    onClick={() => setDrawerOpen(true)}
                  >
                    Stats
                  </Button>
                </Hidden>
                <Button color="inherit" onClick={handleLogout}>
                  Logout
                </Button>
              </Fragment>
            ) : (
                <Button color="inherit" onClick={handleLogin}>
                  Login
                </Button>
              )}
          </Grid>
        </Toolbar>
      </AppBar>
      <Drawer anchor="right" open={drawerOpen} onClose={() => setDrawerOpen(false)}>
        <div
          className={classes.drawer}
          role="presentation"
          onClick={() => setDrawerOpen(false)}
        >
          <StatsCardGroup userID={userID} />
        </div>
      </Drawer>
      <Backdrop className={classes.backdrop} open={logoutLoadState.isLoading}>
        <CircularProgress color="secondary" />
      </Backdrop>
      <DialogAlert dialogFeatures={dialogFeatures} setDialogFeatures={setDialogFeatures} />
    </div>
  )
}

export default NavBar
