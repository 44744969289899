import { Metadata } from "..";

export interface SnippetInterface {
  id: number;
  snippet_name: string;
  snippet_content: string;
  programming_language: string;
  theme: string;
  date_created: string;
  user_id: number;
  collection_id: number;
};

export interface SnippetSearchListInterface {
  metadata: Metadata;
  results: Array<SnippetInterface>;
};

export interface SnippetSearchListLoadStateInterface {
  isLoading: boolean;
  isError: boolean;
  data: SnippetSearchListInterface;
};

export interface SnippetCollectionInterface {
  collection_name: string;
  collection_id: number;
  snippets: Array<SnippetInterface>;
};

export interface SnippetCollectionListInterface {
  metadata: Metadata;
  results: Array<SnippetCollectionInterface>;
}

export interface SnippetCollectionListLoadStateInterface {
  isLoading: boolean;
  isError: boolean;
  data: SnippetCollectionListInterface;
};

export const initialSnippetSearchList: SnippetSearchListInterface = {
  metadata: {
    total_count: 0,
    current_page: 1,
    previous_page: '',
    next_page: '',
    total_page: 1,
    current_count: 0
  },
  results: [
    {
      id: 0,
      snippet_name: '',
      snippet_content: '',
      programming_language: '',
      theme: '',
      date_created: '',
      user_id: 0,
      collection_id: 0
    }
  ]
};

export const initialSnippetCollectionList: SnippetCollectionListInterface = {
  metadata: {
    total_count: 0,
    current_page: 1,
    previous_page: '',
    next_page: '',
    total_page: 1,
    current_count: 0
  },
  results: [
    {
      collection_name: '',
      collection_id: 0,
      snippets: [
        {
          id: 0,
          snippet_name: '',
          snippet_content: '',
          programming_language: '',
          theme: '',
          date_created: '',
          user_id: 0,
          collection_id: 0
        }
      ]
    }
  ]
};
