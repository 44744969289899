import React, { useState, useEffect, useCallback } from 'react';
import { environment } from '../../../package.json';
import ReCAPTCHA from "react-google-recaptcha";
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import { Grid, FormControl, TextField, Button, InputAdornment, IconButton, Backdrop, CircularProgress, FormControlLabel, Checkbox, Link, Typography, FormHelperText } from '@material-ui/core';
import Email from '../../assets/images/illustrations/email.svg';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { Registration, RegistrationisFilled, GenericResponseLoadState, initialGenericResponse, Options, DialogFeatures, initialDialogFeatures } from '../../@types';
import { useCallApi } from '../../@utils';
import DialogAlert from '../utility/DialogAlert';

const useStyles = makeStyles((theme: Theme) => ({
  formfield: {
    width: '100%',
    marginBottom: 20,
    '& .MuiIconButton-root': {
      color: theme.palette.text.secondary,
      padding: 8,
    },
  },
  checkbox: {
    width: '100%',
    marginTop: -10,
    marginBottom: 20,
    '& .MuiFormControlLabel-root': {
      margin: 0,
      '& .MuiTypography-root': {
        fontSize: '0.7rem',
        '& a': {
          color: theme.palette.secondary.main,
        },
      },
    },
  },
  recaptcha: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 30,
    '& #recaptchaContainer': {
      transform: 'scale(1)',
      '-webkit-transform': 'scale(1)',
    },
  },
  registerButton: {
    '& .MuiButton-root': {
      boxShadow: 'none',
      width: '100%',
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  '@media only screen and (max-width: 600px)': {
    recaptcha: {
      '& #recaptchaContainer': {
        transform: 'scale(0.77)',
        '-webkit-transform': 'scale(0.77)',
      },
    },
  },
}));

interface RegistrationFormProps {
  triggerClose: () => void;
}

const RegistrationForm: React.FC<RegistrationFormProps> = ({ triggerClose }: RegistrationFormProps) => {
  const classes = useStyles();
  const theme = useTheme();
  const [dialogFeatures, setDialogFeatures] = useState<DialogFeatures>(initialDialogFeatures);

  const [registrationLoadState, registrationOptions]: [GenericResponseLoadState, Options] = useCallApi(
    'authentication/register/',
    initialGenericResponse,
    'POST',
    false,
    'PUBLIC'
  );

  const [form, setForm] = useState<Registration>({
    username: '',
    email: '',
    password: '',
    re_password: '',
    checked: false,
  });

  const [isFilled, setIsFilled] = useState<RegistrationisFilled>({
    username: true,
    email: true,
    password: true,
    re_password: true,
    checked: true,
  });

  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showRePassword, setShowRePassword] = useState<boolean>(false);
  const [passwordError, setPasswordError] = useState<boolean>(false);

  // Handle ReCAPTCHA
  const [notRobot, setNotRobot] = useState<boolean>(false);

  const getSiteKey = () => {
    switch (environment) {
      case 'local':
        return '6LfvcfoZAAAAAEoaH2reEun-tBUTbITSxX4o6ENQ'
      case 'prod':
        return '6LfobvoZAAAAAIW4YiOPKeR07pGSG7lsKi6tAJNn'
      default:
        return '6LfobvoZAAAAAIW4YiOPKeR07pGSG7lsKi6tAJNn'
    }
  }

  const handleSubmit = () => {
    // Show Errors
    for (let [field, value] of Object.entries(form)) {
      setIsFilled(prev => ({
        ...prev,
        [field]: !value ? false : true
      }));
    };

    if (form.password !== form.re_password) {
      setPasswordError(true);
    };

    // Validation
    if (!Object.values(form).includes('') && (form.password === form.re_password) && form.checked && notRobot) {
      registrationOptions.setData(form);
    };
  };

  const handleSuccess = useCallback(() => {
    setDialogFeatures({
      open: true,
      title: 'Welcome!',
      icon: <img src={Email} alt="success" width={150} height={150} />,
      color: `${theme.palette.success.main}80`,
      width: 350,
      message: 'An email has been sent to you, please click on the link to activate your account!',
      // confetti: true, // Already have confetti in activation page
      customCancelAction: () => {
        setDialogFeatures(initialDialogFeatures);
        triggerClose()
      }
    })

  }, [theme.palette.success.main, triggerClose]);

  useEffect(() => {
    if (registrationLoadState.data.success) {
      handleSuccess();
    }
  }, [registrationLoadState.data.success, handleSuccess]);

  return (
    <Grid container spacing={0}>
      <Grid item xs={12}>
        <form onSubmit={handleSubmit}>
          <FormControl className={classes.formfield} component="fieldset">
            <TextField
              fullWidth
              id="username"
              name="username"
              label='Username'
              autoComplete="off"
              variant="filled"
              color="secondary"
              onChange={(e) => {
                setForm(prev => ({
                  ...prev,
                  username: e.target.value
                }));
                setIsFilled(prev => ({
                  ...prev,
                  username: e.target.value.length === 0 ? false : true
                }));
                e.persist();
              }}
              error={!!registrationOptions.error.username || !isFilled.username}
              helperText={!isFilled.username ? 'This field cannot be blank' : registrationOptions.error.username ? registrationOptions.error.username[0] : ' '}
            />
          </FormControl>
          <FormControl className={classes.formfield} component="fieldset">
            <TextField
              fullWidth
              id="email"
              name="email"
              label='Email'
              autoComplete="off"
              variant="filled"
              color="secondary"
              onChange={(e) => {
                setForm(prev => ({
                  ...prev,
                  email: e.target.value
                }));
                setIsFilled(prev => ({
                  ...prev,
                  email: e.target.value.length === 0 ? false : true
                }));
                e.persist();
              }}
              error={!!registrationOptions.error.email || !isFilled.email}
              helperText={!isFilled.email ? 'This field cannot be blank' : registrationOptions.error.email ? registrationOptions.error.email[0] : ' '}
            />
          </FormControl>
          <FormControl className={classes.formfield} component="fieldset">
            <TextField
              fullWidth
              id="password"
              name="password"
              label="Password"
              autoComplete="off"
              variant="filled"
              type={showPassword ? 'text' : 'password'}
              color="secondary"
              onChange={(e) => {
                setForm(prev => ({
                  ...prev,
                  password: e.target.value
                }));
                setIsFilled(prev => ({
                  ...prev,
                  password: e.target.value.length === 0 ? false : true
                }));
                setPasswordError(false);
                e.persist();
              }}
              error={!!registrationOptions.error.password || !isFilled.password}
              helperText={!isFilled.password ? 'This field cannot be blank' : registrationOptions.error.password ? registrationOptions.error.password[0] : ' '}
              InputProps={{
                endAdornment:
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword(prev => !prev)}
                      onMouseDown={(event: React.MouseEvent<HTMLButtonElement>) => event.preventDefault()}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
              }}
            />
          </FormControl>
          <FormControl className={classes.formfield} component="fieldset">
            <TextField
              fullWidth
              id="re_password"
              name="re_password"
              label='Re-enter Your Password'
              autoComplete="off"
              variant="filled"
              type={showRePassword ? 'text' : 'password'}
              color="secondary"
              onChange={(e) => {
                setForm(prev => ({
                  ...prev,
                  re_password: e.target.value
                }));
                setIsFilled(prev => ({
                  ...prev,
                  re_password: e.target.value.length === 0 ? false : true
                }));
                setPasswordError(false);
                e.persist();
              }}
              error={passwordError || !isFilled.re_password}
              helperText={!isFilled.re_password ? 'This field cannot be blank' : passwordError ? 'Passwords do not match' : ' '}
              InputProps={{
                endAdornment:
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowRePassword(prev => !prev)}
                      onMouseDown={(event: React.MouseEvent<HTMLButtonElement>) => event.preventDefault()}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
              }}
            />
          </FormControl>
          <FormControl className={classes.checkbox} component="fieldset" error={!isFilled.checked}>
            <FormControlLabel
              control={
                <Checkbox
                  color="secondary"
                  checked={form.checked}
                  onChange={() => {
                    setForm(prev => ({
                      ...prev,
                      checked: !prev.checked
                    }));
                  }}
                />
              }
              label={
                <Typography variant="body2" component="span">
                  {`I agree to the `}
                  <Link href="/terms-and-conditions" target="_blank" rel="noopener noreferrer" aria-label="CodeCache Terms and Conditions">Terms & Conditions</Link>
                  {` and `}
                  <Link href="/privacy-policy" target="_blank" rel="noopener noreferrer" aria-label="CodeCache Privacy Policy">Privacy Policy</Link>
                </Typography>
              }
              
            />
            <FormHelperText>{isFilled.checked ? ' ' : 'Please check the box to proceed'}</FormHelperText>
          </FormControl>
        </form>
      </Grid>
      <Grid item xs={12} className={classes.recaptcha}>
        <div id="recaptchaContainer">
          <ReCAPTCHA
            sitekey={getSiteKey()}
            onChange={() => setNotRobot(true)}
          />
        </div>
      </Grid>
      <Grid item xs={12} className={classes.registerButton}>
        <Button variant="contained" color="primary" onClick={handleSubmit} disabled={!notRobot}>
          Register!
        </Button>
      </Grid>
      <Backdrop className={classes.backdrop} open={registrationLoadState.isLoading}>
        <CircularProgress color="secondary" />
      </Backdrop>
      <DialogAlert dialogFeatures={dialogFeatures} setDialogFeatures={setDialogFeatures} />
    </Grid>
  )
}

export default RegistrationForm
