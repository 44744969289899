export interface SnippetInterface {
  id: number;
  snippet_name: string;
  snippet_content: string;
  programming_language: string;
  theme: string;
  date_created: string;
  user_id: number;
  collection_id: number;
};

export interface SnippetLoadStateInterface {
  isLoading: boolean;
  isError: boolean;
  data: SnippetInterface;
};

export const initialSnippet: SnippetInterface = {
  id: 0,
  snippet_name: '',
  snippet_content: '',
  programming_language: '',
  theme: '',
  date_created: '',
  user_id: 0,
  collection_id: 0
}