import React, { useState, useEffect } from 'react';
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import { Grid, Typography, Button, Hidden } from '@material-ui/core';
import { DialogFeatures, Feature, initialDialogFeatures } from '../../@types';
import DialogAlert from '../../components/utility/DialogAlert';
import RegistrationForm from '../../components/forms/RegistrationForm';
import { useHistory } from 'react-router-dom';
import FeatureCard from '../../components/cards/FeatureCard';
import PatreonExpandingButtonWithContact from '../../components/buttons/PatreonExpandingButtonWithContact';
import { landingContent } from '../../assets/content/landingContent';
import Footer from '../../components/structure/Footer';

const useStyles = makeStyles<Theme>((theme: Theme) => ({
  root: {
    marginTop: 100,
    overflow: 'hidden'
  },
  info: {
    marginTop: 50,
    marginBottom: 100,
    '& .MuiGrid-root': {
      display: 'flex',
      alignItems: 'center',
      textAlign: 'justify',
      marginBottom: 80,
      '& img': {
        width: '100%',
      },
    },
  },
  container: {
    marginBottom: 80,
    '& .MuiGrid-root': {
      display: 'flex',
      justifyContent: 'center',
      textAlign: 'center',
    },
  },
  joinNowButton: {
    '& .MuiButton-root': {
      width: 150,
    },
  },
  registerButton: {
    '& .MuiButton-root': {
      margin: 30,
    },
  },
  buttonPulse: {
    boxShadow: `0 0 0 0 ${theme.palette.primary.main}80`,
    animation: '$pulse 1.5s ease infinite',
  },
  '@keyframes pulse': {
    '0%': {
      transform: 'scale(0.9)',
    },
    '70%': {
      transform: 'scale(1)',
      boxShadow: `0 0 0 15px ${theme.palette.primary.main}00`,
    },
    '100%': {
      transform: 'scale(0.9)',
      boxShadow: `0 0 0 0 ${theme.palette.primary.main}00`
    }
  },
  '@media only screen and (max-width: 600px)': {
    root: {
      marginTop: 80,
      textAlign: 'center',
      '& h6': {
        textAlign: 'justify',
        textAlignLast: 'center',
      },
    },
    info: {
      marginBottom: 20,
      '& .MuiGrid-root': {
        marginBottom: 20,
        textAlignLast: 'center',
        '& img': {
          marginTop: 20,
          padding: 20,
        },
      },
    },
  },
}));

const Landing: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();

  const [dialogFeatures, setDialogFeatures] = useState<DialogFeatures>(initialDialogFeatures);
  const handleJoinNow = () => {
    setDialogFeatures({
      open: true,
      title: 'Create Your Account',
      color: `${theme.palette.primary.main}80`,
      customBodyActions: <RegistrationForm triggerClose={() => setDialogFeatures(initialDialogFeatures)} />,
    })
  };

  useEffect(() => {
    //redirect to dashboard if user has already logged in before
    if (localStorage.getItem('token')) history.push('/dashboard')
  });

  return (
    <Grid container spacing={0} className={classes.root}>
      <Grid item xs={1} lg={2} xl={3}></Grid>
      <Grid item xs={10} lg={8} xl={6}>
        <Grid container spacing={5}>
          <Hidden xsDown>
            <Grid item xs={12}>
              <Typography variant="h2" color="textPrimary">{landingContent.header}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6" color="textPrimary" gutterBottom>{landingContent.subheader}</Typography>
            </Grid>
          </Hidden>
          <Hidden smUp>
            <Grid item xs={12}>
              <Typography variant="h3" color="textPrimary">{landingContent.header}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle1" color="textPrimary" gutterBottom>{landingContent.subheader}</Typography>
            </Grid>
          </Hidden>
          <Grid item xs={12} className={classes.joinNowButton}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleJoinNow}
              size="large"
              className={classes.buttonPulse}
            >
              Join now!
            </Button>
          </Grid>
        </Grid>
        <Grid container spacing={3} className={classes.info}>
          <Hidden xsDown>
            <Grid item xs={5}>
              <div>
                <Typography variant="h5" component="h5" gutterBottom>{landingContent.sections[0].title}</Typography>
                <Typography variant="body1" component="p">{landingContent.sections[0].content}</Typography>
              </div>
            </Grid>
            <Grid item xs={2} />
            <Grid item xs={5}>
              <img src={landingContent.sections[0].image} alt={landingContent.sections[0].title} />
            </Grid>
            <Grid item xs={5}>
              <img src={landingContent.sections[1].image} alt={landingContent.sections[1].title} />
            </Grid>
            <Grid item xs={2} />
            <Grid item xs={5}>
              <div>
                <Typography variant="h5" component="h5" gutterBottom>{landingContent.sections[1].title}</Typography>
                <Typography variant="body1" component="p">{landingContent.sections[1].content}</Typography>
              </div>
            </Grid>
            <Grid item xs={5}>
              <div>
                <Typography variant="h5" component="h5" gutterBottom>{landingContent.sections[2].title}</Typography>
                <Typography variant="body1" component="p">{landingContent.sections[2].content}</Typography>
              </div>
            </Grid>
            <Grid item xs={2} />
            <Grid item xs={5}>
              <img src={landingContent.sections[2].image} alt={landingContent.sections[2].title} />
            </Grid>
            <Grid item xs={5}>
              <img src={landingContent.sections[3].image} alt={landingContent.sections[3].title} />
            </Grid>
            <Grid item xs={2} />
            <Grid item xs={5}>
              <div>
                <Typography variant="h5" component="h5" gutterBottom>{landingContent.sections[3].title}</Typography>
                <Typography variant="body1" component="p" gutterBottom>{landingContent.sections[3].content}</Typography>
              </div>
            </Grid>
          </Hidden>
          <Hidden smUp>
            <Grid item xs={12}>
              <div>
                <Typography variant="h5" component="h5" gutterBottom>{landingContent.sections[0].title}</Typography>
                <Typography variant="body1" component="p">{landingContent.sections[0].content}</Typography>
                <img src={landingContent.sections[0].image} alt={landingContent.sections[0].title} />
              </div>
            </Grid>
            <Grid item xs={12}>
              <div>
                <Typography variant="h5" component="h5" gutterBottom>{landingContent.sections[1].title}</Typography>
                <Typography variant="body1" component="p">{landingContent.sections[1].content}</Typography>
                <img src={landingContent.sections[1].image} alt={landingContent.sections[1].title} />
              </div>
            </Grid>
            <Grid item xs={12}>
              <div>
                <Typography variant="h5" component="h5" gutterBottom>{landingContent.sections[2].title}</Typography>
                <Typography variant="body1" component="p">{landingContent.sections[2].content}</Typography>
                <img src={landingContent.sections[2].image} alt={landingContent.sections[2].title} />
              </div>
            </Grid>
            <Grid item xs={12}>
              <div>
                <Typography variant="h5" component="h5" gutterBottom>{landingContent.sections[3].title}</Typography>
                <Typography variant="body1" component="p" gutterBottom>{landingContent.sections[3].content}</Typography>
                <img src={landingContent.sections[3].image} alt={landingContent.sections[3].title} />
              </div>
            </Grid>
          </Hidden>
        </Grid>
        <Grid container spacing={5} className={classes.container}>
          <Grid item xs={12}>
            <Typography variant="h4" component="h4">Features</Typography>
          </Grid>
          {landingContent.features.map((feature: Feature) => {
            return <Grid item xs={12} md={4} key={feature.title}>
              <FeatureCard
                title={feature.title}
                image={feature.image}
                bodyText={feature.content}
              />
            </Grid>
          })}
        </Grid>
        <Grid container spacing={2} className={classes.container}>
          <Grid item xs={12}>
            <Typography variant="h4" component="h4">Try out CodeCache today!</Typography>
          </Grid>
          <Grid item xs={12} className={classes.registerButton}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleJoinNow}
              size="large"
              className={classes.buttonPulse}
            >
              Sign Up
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6" component="h6">Or contact us below to find out more</Typography>
          </Grid>
        </Grid>
        <Footer />
      </Grid>
      <Grid item xs={1} lg={2} xl={3}></Grid>
      <DialogAlert dialogFeatures={dialogFeatures} setDialogFeatures={setDialogFeatures} />
      <PatreonExpandingButtonWithContact floating />
    </Grid >
  )
}

export default Landing
