import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Button, Grid, Typography } from '@material-ui/core';
import NotFound from '../../../assets/images/illustrations/not_found.svg';
import { useHistory } from 'react-router-dom';
import Footer from '../../../components/structure/Footer';

const useStyles = makeStyles<Theme>((theme: Theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
  },
  message: {
    display: 'flex',
    height: '75vh',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    '& img': {
      height: 300,
      width: 300,
    },
  },
  button: {
    marginTop: 50
  },
  footer: {
    position: 'absolute',
    bottom: 10,
    width: '100%',
  },
  '@media only screen and (max-width: 600px)': {
    message: {
      '& img': {
        height: 150,
        width: 150,
      },
    },
    button: {
      marginTop: 20
    },
    footer: {
      position: 'relative',
    },
  },
}));

const VerificationError = () => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <Grid container spacing={0} className={classes.root}>
      <Grid item xs={1} lg={2} xl={3} />
      <Grid item xs={10} lg={8} xl={6} className={classes.message}>
        <Typography variant="h3" gutterBottom>Oops!</Typography>
        <img src={NotFound} alt="Not Found" />
        <Typography variant="h6" gutterBottom>It appears your link is broken, please contact support or try registering again.</Typography>
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={() => history.push('/')}
        >
          Return to CodeCache
        </Button>
      </Grid>
      <Grid item xs={1} lg={2} xl={3} />
      <Grid item xs={1} lg={2} xl={3} />
      <Grid item xs={10} lg={8} xl={6} className={classes.footer}>
        <Footer />
      </Grid>
      <Grid item xs={1} lg={2} xl={3} />
    </Grid>
  )
}

export default VerificationError
