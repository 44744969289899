export interface CurrentUserInterface {
  id: number;
  last_login: string;
  email: string;
  username: string;
  default_programming_language: string;
  default_theme: string;
  is_active: boolean;
  date_joined: string;
};

export interface CurrentUserLoadStateInterface {
  isLoading: boolean;
  isError: boolean;
  data: CurrentUserInterface;
};

export const initialCurrentUser: CurrentUserInterface = {
  id: 0,
  last_login: '',
  email: '',
  username: '',
  default_programming_language: '',
  default_theme: '',
  is_active: false,
  date_joined: ''
};