export const getDefaultContent = (language: string) => {
  switch (language) {
    case 'javascript':
    case 'typescript':
    case 'java':
    case 'golang':
    case 'c_cpp':
    case 'csharp':
    case 'swift':
    case 'scala':
    case 'jsx':
    case 'tsx':
      return `// Enter your code here`;
    case 'python':
    case 'r':
    case 'ruby':
    case 'julia':
    case 'mysql':
    case 'dockerfile':
    case 'gitignore':
      return `# Enter your code here`;
    case 'php':
      return `<?php
// Enter your code here
?>`
    case 'ocaml':
      return `(* Enter your code here *)`;
    case 'haskell':
      return `-- Enter your code here`;
    case 'matlab':
      return`%{ Enter your code here %}`
    case 'html':
      return `<!DOCTYPE html>
<!-- Enter your code here -->`;
    case 'css':
      return `/* Enter your code here */`;
    case 'markdown':
    case 'plain_text':
      return `Enter your text here`;
    case 'json':
      return `{
"comment": "Enter your code here"
}`;
  }
  return ''
};