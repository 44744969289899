import React, { Fragment } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Avatar, Card, CardContent, CardHeader, Grow, List, ListItem, ListItemAvatar, ListItemIcon, ListItemText, Typography } from '@material-ui/core';
import { initialPopularStats, Options, PopularStats, PopularStatsLoadState } from '../../@types';
import { getModeIcon, getModeName, useCallApi } from '../../@utils';
import LoadingAnimation from '../utility/LoadingAnimation';

interface StatsCardStyleProps {
  isLoading: boolean;
}

const useStyles = makeStyles<Theme, StatsCardStyleProps>((theme: Theme) => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    '& .MuiCardContent-root': {
      flex: '1 1 1px',
      overflow: 'scroll',
    },
  },
  popularList: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  '@media only screen and (max-height: 1180px)': {
    root: {
      '& .MuiCardContent-root': {
        overflow: 'hidden',
      },
    },
  },
}));

interface LargeStatsCardProps {
  userID: number;
}

const LargeStatsCard: React.FC<LargeStatsCardProps> = ({ userID }: LargeStatsCardProps) => {
  const [popularStatsLoadState]: [PopularStatsLoadState, Options] = useCallApi(
    `snippet/popular/`,
    initialPopularStats,
    'GET',
    true,
    'PRIVATE'
  );

  const styleProps: StatsCardStyleProps = {
    isLoading: popularStatsLoadState.isLoading
  }
  const classes = useStyles(styleProps);

  return (
    <Grow in timeout={1500}>
      <Card className={classes.root}>
        <CardHeader
          title='CodeCache Most Popular'
        />
        <CardContent>
          <List className={classes.popularList}>
            {popularStatsLoadState.isLoading ? (
              <LoadingAnimation />
            ) : (
                <Fragment>
                  {popularStatsLoadState.data.map((popularStat: PopularStats, index: number) => {
                    return <ListItem key={index}>
                      <ListItemIcon><Typography variant='h6' component='h6'>{`${index + 1}. `}</Typography></ListItemIcon>
                      <ListItemAvatar><Avatar variant='square' src={getModeIcon(popularStat.mode)} alt={popularStat.mode} /></ListItemAvatar>
                      <ListItemText primary={getModeName(popularStat.mode)} />
                    </ListItem>
                  })}
                </Fragment>
              )}
          </List>
        </CardContent>
      </Card>
    </Grow>
  )
}

export default LargeStatsCard