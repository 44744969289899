import React, { Fragment } from 'react';
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Zoom } from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions';
import { DialogFeatures, initialDialogFeatures, DialogAlertStyleProps } from '../../@types';
import Confetti from 'react-dom-confetti';
import { vw } from '../../@constants';

const useStyles = makeStyles<Theme, DialogAlertStyleProps>((theme: Theme) => ({
  dialog: {
    display: 'flex',
    overflowX: 'hidden',
    justifyContent: 'center',
    textAlign: 'center',
    '& .MuiPaper-root': {
      width: (styleProps: DialogAlertStyleProps) => styleProps.width,
    },
    '& .MuiDialogActions-root': {
      justifyContent: 'center',
      '& > * ': {
        boxShadow: 'none',
        width: '50%',
      },
      '& #primaryAction': {
        backgroundColor: (styleProps: DialogAlertStyleProps) => styleProps.buttonPrimaryColor,
      },
      '& #secondaryAction': {
        backgroundColor: (styleProps: DialogAlertStyleProps) => styleProps.buttonSecondaryColor,
      }
    },
  },
  header: {
    marginBottom: 20,
    '& .MuiSvgIcon-root': {
      fill: (styleProps: DialogAlertStyleProps) => styleProps.color,
      fontSize: '5rem',
    },
  },
  closeButton: {
    position: 'absolute',
    top: 0,
    right: 0
  },
  '@media only screen and (max-width: 600px)': {
    dialog: {
      '& .MuiPaper-root': {
        maxWidth: '90vw',
      },
    },
  },
}));

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>,
) {
  return <Zoom ref={ref} {...props} />;
});

interface DialogAlertProps {
  dialogFeatures: DialogFeatures;
  setDialogFeatures: React.Dispatch<React.SetStateAction<DialogFeatures>>;
}

const DialogAlert: React.FC<DialogAlertProps> = ({ dialogFeatures, setDialogFeatures }: DialogAlertProps) => {
  const theme = useTheme();
  const styleProps: DialogAlertStyleProps = {
    color: dialogFeatures.color ? dialogFeatures.color : theme.palette.primary.main,
    buttonPrimaryColor: dialogFeatures.primaryAction?.buttonPrimaryColor ? dialogFeatures.primaryAction?.buttonPrimaryColor : dialogFeatures.color,
    buttonSecondaryColor: dialogFeatures.customSecondaryAction?.buttonSecondaryColor ? dialogFeatures.customSecondaryAction?.buttonSecondaryColor : 'unset',
    width: dialogFeatures.width ? dialogFeatures.width : vw <= 600 ? 300 : 400,
  };

  const classes = useStyles(styleProps);

  const handleClose = () => {
    dialogFeatures.customCancelAction ? dialogFeatures.customCancelAction() : setDialogFeatures(initialDialogFeatures);
  };

  const confettiConfig = {
    angle: 90,
    spread: 100,
    startVelocity: 40,
    elementCount: 200,
    dragFriction: 0.12,
    duration: 6000,
    stagger: 3,
    width: "10px",
    height: "10px",
    perspective: "500px",
    colors: ["#a864fd", "#29cdff", "#78ff44", "#ff718d", "#fdff6a"]
  };

  return (
    <Dialog
      open={dialogFeatures.open}
      className={classes.dialog}
      id='dialog'
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
    >
      <DialogContent>
        {(dialogFeatures.icon || dialogFeatures.title) &&
          <div className={classes.header}>
            {dialogFeatures.icon}
            {dialogFeatures.title &&
              <DialogTitle>
                {dialogFeatures.title}
                {dialogFeatures.subtitle ? dialogFeatures.subtitle : null}
              </DialogTitle>
            }
          </div>
        }
        {dialogFeatures.customBodyActions &&
          <Fragment>
            {dialogFeatures.customBodyActions}
          </Fragment>
        }
        {dialogFeatures.message &&
          <DialogContentText>
            {dialogFeatures.message}
          </DialogContentText>
        }
      </DialogContent>
      <Confetti active={dialogFeatures.confetti ? dialogFeatures.confetti : false} config={confettiConfig} />
      <DialogActions>
        {dialogFeatures.customSecondaryAction &&
          <Button variant='contained' onClick={dialogFeatures.customSecondaryAction.buttonFunction} id='secondaryAction'>
            {dialogFeatures.customSecondaryAction.buttonText}
          </Button>
        }
        {dialogFeatures.cancelButton &&
          <Button variant='contained' onClick={handleClose}>
            Cancel
          </Button>
        }
        {dialogFeatures.primaryAction &&
          <Button variant='contained' onClick={dialogFeatures.primaryAction.buttonFunction} id='primaryAction'>
            {dialogFeatures.primaryAction.buttonText}
          </Button>
        }
      </DialogActions>
    </Dialog>
  );
}

export default DialogAlert