import React, { useEffect } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import PreferencesSettingsCard from '../../../components/cards/PreferencesSettingsCard';
import ChangePasswordCard from '../../../components/cards/ChangePasswordCard';
import { CollectionListLoadState, CurrentUserLoadState, initialCollectionList, Options } from '../../../@types';
import AppearanceSettingsCard from '../../../components/cards/AppearanceSettingsCard';
import DonationCard from '../../../components/cards/DonationCard';
import { useCallApi } from '../../../@utils';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    height: '100%',
    '& .MuiGrid-container': {
      height: '100%',
    },
  },
  container: {
    height: '100%',
    '& ::-webkit-scrollbar': {
      display: 'none',
    },
  },
  cardColumn: {
    height: '100%',
    overflow: 'scroll',
    '& #top': {
      paddingBottom: 12,
    },
    '& #bottom': {
      paddingTop: 12,
    },
    '& .MuiCard-root': {
      '& .MuiCardHeader-action': {
        marginTop: 0,
      },
      '& .MuiCardContent-root': {
        display: 'flex',
        alignItems: 'center',
        height: 'calc(100% - 64px)',
      },
      height: '100%',
    },
  },
  '@media only screen and (max-width: 959px)': { // MUI breakpoint is inclusive of 960px for md size
    root: {
      height: 'fit-content',
    },
  },
}));

interface SettingsPageProps {
  currentUserLoadState: CurrentUserLoadState;
  setDisableAdd: React.Dispatch<React.SetStateAction<boolean>>;
  supportsPWA: boolean;
  promptToInstall: () => void;
}

const SettingsPage: React.FC<SettingsPageProps> = ({currentUserLoadState, setDisableAdd, supportsPWA, promptToInstall}: SettingsPageProps) => {
  const classes = useStyles();

  const [collectionListLoadState, collectionListOptions]: [CollectionListLoadState, Options] = useCallApi(
    `collection/${currentUserLoadState.data.id}/`,
    initialCollectionList,
    'GET',
    false,
    'PRIVATE'
  );

  // Wait until userID is obtained before loading collectionList
  useEffect(() => {
    if (currentUserLoadState.data.id !== 0) collectionListOptions.setShouldFetch(true);
  }, [currentUserLoadState.data.id, collectionListOptions]);

  // Do not allow user to add snippet without any collections
  useEffect(() => {
    if (collectionListLoadState.isError && collectionListOptions.error.message === 'User has no collections.') {
      setDisableAdd(true);
    }
  }, [collectionListLoadState.isError, collectionListOptions.error.message, setDisableAdd]);

  return (
    <Grid container spacing={3} className={classes.root}>
      <Grid item xs={12} md={6} className={classes.container}>
        <Grid container spacing={0} className={classes.cardColumn}>
          <Grid item xs={12} id="top">
            <AppearanceSettingsCard supportsPWA={supportsPWA} promptToInstall={promptToInstall} />
          </Grid>
          <Grid item xs={12} id="bottom">
            <ChangePasswordCard userID={currentUserLoadState.data.id} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={6} className={classes.container}>
        <Grid container spacing={0} className={classes.cardColumn}>
          <Grid item xs={12} id="top">
            <PreferencesSettingsCard initialDefaultMode={currentUserLoadState.data.default_programming_language} initialDefaultTheme={currentUserLoadState.data.default_theme} />
          </Grid>
          <Grid item xs={12} id="bottom">
            <DonationCard />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default SettingsPage
