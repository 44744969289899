import React, { Fragment, useCallback, useContext, useEffect, useState } from 'react';
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import { Button, Drawer, Hidden, Tab, Tabs, Typography, Avatar, Toolbar } from '@material-ui/core';
import { AddBox, Code, Settings } from '@material-ui/icons';
import { Route, Switch, useHistory, useLocation } from 'react-router-dom';
import { useCallApi } from '../../@utils';
import { CurrentUserLoadState, DialogFeatures, initialCurrentUser, initialDialogFeatures, Options } from '../../@types';
import SettingsPage from './tabs/SettingsPage';
import MySnippets from './tabs/MySnippets';
import AddSnippetFloatingButton from '../../components/buttons/AddSnippetFloatingButton';
import NewSnippetForm from '../../components/forms/NewSnippetForm';
import DialogAlert from '../../components/utility/DialogAlert';
import { Skeleton } from '@material-ui/lab';
import ResponseSnackbar from '../../components/utility/ResponseSnackbar';
import { ThemeContext } from '../../context/ThemeContext';
import { UserDefaultsContext } from '../../context/UserDefaultsContext';

const DRAWER_WIDTH = 240;

interface DashboardStyleProps {
  darkMode: boolean;
}

const useStyles = makeStyles<Theme, DashboardStyleProps>((theme: Theme) => ({
  root: {
    display: 'flex',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: DRAWER_WIDTH,
      flexShrink: 0,
    },
  },
  drawerContents: {
    '& .MuiTabs-flexContainerVertical': {
      width: DRAWER_WIDTH,
      '& .MuiTab-wrapper': {
        flexDirection: 'row',
        justifyContent: 'flex-start',
        '& svg': {
          margin: '6px 30px 6px 15px',
        },
        '& .MuiTab-wrapped': {
          fontSize: '1.25rem',
        }
      },
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: DRAWER_WIDTH,
    borderRight: 'none',
    background: styleProps => styleProps.darkMode ? `linear-gradient(${theme.palette.background.paper}, #29323c, #485563)` : `linear-gradient(${theme.palette.background.paper}, #DDDDDD, #CECECE)`,
  },
  buttonGroup: {
    textAlign: 'center',
  },
  greeting: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    '& span': {
      color: theme.palette.text.secondary,
    },
    '& .MuiSkeleton-circle': {
      marginBottom: 20,
    },
  },
  avatar: {
    color: theme.palette.getContrastText(theme.palette.secondary.main),
    backgroundColor: theme.palette.secondary.main,
    marginBottom: 20,
  },
  content: {
    width: '100%',
    height: `calc(100vh - ${theme.mixins.toolbar.minHeight}px - 8px)`,
    paddingTop: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  tabs: {
    marginTop: 50,
    borderRight: `1px solid ${theme.palette.divider}`,
  },
  snackbar: {
    [theme.breakpoints.down('xs')]: {
      bottom: 100,
    },
  },
  '@media only screen and (max-width: 600px)': {
    root: {
      marginLeft: 0,
    },
  }
}));

interface DashboardProps {
  setUserID: React.Dispatch<React.SetStateAction<number>>;
  mobileOpen: boolean;
  setMobileOpen: React.Dispatch<React.SetStateAction<boolean>>;
  supportsPWA: boolean;
  promptToInstall: () => void;
}

const Dashboard: React.FC<DashboardProps> = ({setUserID, mobileOpen, setMobileOpen, supportsPWA, promptToInstall}: DashboardProps) => {
  const { darkMode } = useContext(ThemeContext);
  const styleProps: DashboardStyleProps = {
    darkMode: darkMode
  }

  const classes = useStyles(styleProps);
  const theme = useTheme();
  const history = useHistory();
  const location = useLocation();

  const [currentUserLoadState, currentUserOptions]: [CurrentUserLoadState, Options] = useCallApi(
    'authentication/current/',
    initialCurrentUser,
    'GET',
    true,
    'PRIVATE'
  );

  useEffect(() => {
    if (!currentUserLoadState.isLoading) setUserID(currentUserLoadState.data.id)
  }, [currentUserLoadState.data.id, currentUserLoadState.isLoading, setUserID]);

  // User ID is passed as props to other components but default mode and theme are stored in context
  const { setUserID: setContextUserID, setDefaultMode, setDefaultTheme } = useContext(UserDefaultsContext);

  useEffect(() => {
    if (currentUserLoadState.data.id) {
      setContextUserID(currentUserLoadState.data.id);
      setDefaultMode(currentUserLoadState.data.default_programming_language);
      setDefaultTheme(currentUserLoadState.data.default_theme);
    }
  }, [currentUserLoadState.data.id, currentUserLoadState.data.default_programming_language, currentUserLoadState.data.default_theme, setContextUserID, setDefaultMode, setDefaultTheme]);

  // Check if user is logged in, redirect to main screen if user does not have token
  useEffect(() => {
    if (currentUserOptions.status === 401) {
      localStorage.removeItem('token')
      history.push('/');
      window.location.reload();
    }
  }, [history, currentUserOptions.status, currentUserLoadState.data]);

  // Snackbar for Add Snippet Success
  const [message, setMessage] = useState<string>('');

  // Add Snippet Handler
  const [disableAdd, setDisableAdd] = useState<boolean>(false);
  const [dialogFeatures, setDialogFeatures] = useState<DialogFeatures>(initialDialogFeatures);
  const handleClickAdd = () => {
    setDialogFeatures({
      open: true,
      title: 'Add Code Snippet',
      width: 600,
      color: `${theme.palette.primary.main}80`,
      customBodyActions: <NewSnippetForm userID={currentUserLoadState.data.id} triggerClose={() => setDialogFeatures(initialDialogFeatures)} setMessage={setMessage} />,
    });
  };

  // Navigation Tab Handler
  const [tabValue, setTabValue] = useState<number>(0);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setMobileOpen(false);
    switch (newValue) {
      case 0:
        history.push('/dashboard');
        break
      case 1:
        history.push('/dashboard/settings');
        break
    }
    setTabValue(newValue);
  };

  const getTabValue = useCallback(() => {
    if (location.pathname.includes('/settings')) {
      return 1
    } else {
      return 0
    }
  }, [location.pathname]);

  useEffect(() => {
    setTabValue(getTabValue())
  }, [location, getTabValue]);

  const drawer = (
    <div className={classes.drawerContents}>
      <div className={classes.toolbar} />
      <section className={classes.greeting}>
        {currentUserLoadState.isLoading ? (
          <Fragment>
            <Skeleton animation="wave" variant="circle" width={40} height={40} />
            <Skeleton animation="wave" width={120} height={32} />
            <Skeleton animation="wave" width={100} height={21} />
          </Fragment>
        ) : (
            <Fragment>
              <Avatar className={classes.avatar} alt="avatar-image">{currentUserLoadState.data.username.charAt(0).toUpperCase()}</Avatar>
              <Typography variant="h5"> Hello, {currentUserLoadState.data.username}</Typography>
              <Typography variant="subtitle2" component="span">{currentUserLoadState.data.email}</Typography>
            </Fragment>
          )}
      </section>
      <div className={classes.toolbar} />
      <Hidden xsDown>
        <section className={classes.buttonGroup}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleClickAdd}
            startIcon={<AddBox />}
            disabled={disableAdd}
          >
            Add Snippet
          </Button>
        </section>
      </Hidden>
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={tabValue}
        onChange={handleChange}
        className={classes.tabs}
        indicatorColor="primary"
        textColor="primary"
      >
        <Tab label="My Snippets" title="My Snippets" icon={<Code />} color="primary" wrapped />
        <Tab label="Settings" title="Settings" icon={<Settings />} color="primary" wrapped />
      </Tabs>
    </div>
  );

  return (
    <div className={classes.root}>
      <nav className={classes.drawer}>
        <Hidden smUp implementation="css">
          <Drawer
            variant="temporary"
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={mobileOpen}
            onClose={() => setMobileOpen(false)}
            classes={{
              paper: classes.drawerPaper
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            <Toolbar />
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      <main className={classes.content}>
        <Switch>
          <Route path="/dashboard/settings" render={(settingsPageProps) => <SettingsPage {...settingsPageProps} currentUserLoadState={currentUserLoadState} setDisableAdd={setDisableAdd} supportsPWA={supportsPWA} promptToInstall={promptToInstall} />} />
          <Route path="/" render={(mySnippetsProps) => <MySnippets {...mySnippetsProps} userID={currentUserLoadState.data.id} setDisableAdd={setDisableAdd} />} />
        </Switch>
      </main>
      <DialogAlert dialogFeatures={dialogFeatures} setDialogFeatures={setDialogFeatures} />
      <Hidden smUp>
        {!disableAdd &&
          <AddSnippetFloatingButton handleClickAdd={handleClickAdd} />
        }
      </Hidden>
      <ResponseSnackbar message={message} setMessage={setMessage} severity='success' />
    </div >
  )
}

export default Dashboard