import { useCallback, useRef } from "react";
import { Options, State } from "../../@types";

const useInfiniteScrollingObserver = (loadState: State, options: Options, setPage:  React.Dispatch<React.SetStateAction<number>>) => {
  const hasMore = !(loadState.data.metadata.current_page === loadState.data.metadata.total_page);

  const observer: React.MutableRefObject<any> = useRef();
  const lastElementRef = useCallback(node => {
    if (loadState.isLoading) return
    if (observer.current) observer.current.disconnect()
    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && hasMore) {
        setPage(prev => prev + 1);
        options.setShouldFetch(true)
      }
    }, { threshold: 0.99 })
    if (node) observer.current.observe(node)
  }, [hasMore, setPage, loadState.isLoading, options]);

  return [lastElementRef]
}

export default useInfiniteScrollingObserver
