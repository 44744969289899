import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  spinner: {
    margin: 'auto',
    paddingTop: 20,
    paddingBottom: 20,
    width: 45,
    textAlign: 'center',
    '& > *': {
      width: 15,
      height: 15,
      backgroundColor: theme.palette.secondary.main,
      borderRadius: '100%',
      display: 'inline-block',
      animation: '$bouncedelay 1.4s infinite ease-in-out both',
    },
  },
  bounce1: {
    animationDelay: '-0.32s',
  },
  bounce2: {
    animationDelay: '-0.16s',
  },
  '@keyframes bouncedelay': {
    '0%': {
      transform: 'scale(0)',
    },
    '80%': {
      transform: 'scale(0)',
    },
    '100%': {
      transform: 'scale(0)',
    },
    '40%': {
      transform: 'scale(1)',
    }
  },
}));

export default function LoadingAnimation() {
  const classes = useStyles();

  return (
    <div className={classes.spinner}>
      <div className={classes.bounce1}></div>
      <div className={classes.bounce2}></div>
      <div></div>
    </div>
  );
}