import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { useCallApi } from '../../@utils';
import { DonutStats, DonutStatsLoadState, initialDonutStats, Options } from '../../@types';
import { Code, Dns } from '@material-ui/icons';
import SmallStatsCard from '../cards/SmallStatsCard';
import DonutCard from '../cards/DonutCard';
import LargeStatsCard from '../cards/LargeStatsCard';


const useStyles = makeStyles((theme: Theme) => ({
  summaryCards: {
    height: '100%',
    '& .MuiGrid-item': {
      padding: 12,
    },
    '& #popularCard': {
      height: 'calc(100% - 688px)',
    },
  },
  '@media only screen and (max-height: 1180px)': {
    summaryCards: {
      overflow: 'scroll',
      '& #noPaddingWhenScroll': {
        paddingTop: 0
      },
      '& #popularCard': {
        height: 700,
        paddingBottom: 0,
      },
    },
  },
}));

interface StatsCardGroupProps {
  userID: number;
}

const StatsCardGroup: React.FC<StatsCardGroupProps> = ({ userID }: StatsCardGroupProps) => {
  const classes = useStyles();

  const [donutStatsLoadState]: [DonutStatsLoadState, Options] = useCallApi(
    `snippet/donut/${userID}/`,
    initialDonutStats,
    'GET',
    true,
    'PRIVATE'
  );

  return (
    <Grid container spacing={0} className={classes.summaryCards}>
      <Grid item xs={6} id="noPaddingWhenScroll">
        <SmallStatsCard
          isLoading={donutStatsLoadState.isLoading}
          icon={<Code color="primary" />}
          color="primary"
          info={donutStatsLoadState.data.reduce((acc: number, donutStat: DonutStats) => acc + donutStat.y, 0)}
          label="Snippets"
        />
      </Grid>
      <Grid item xs={6} id="noPaddingWhenScroll">
        <SmallStatsCard
          isLoading={donutStatsLoadState.isLoading}
          icon={<Dns color="secondary" />}
          color="secondary"
          info={donutStatsLoadState.data.length}
          label="Languages"
        />
      </Grid>
      <Grid item xs={12}>
        <DonutCard donutStatsLoadState={donutStatsLoadState} />
      </Grid>
      <Grid item xs={12} id="popularCard">
        <LargeStatsCard userID={userID} />
      </Grid>
    </Grid>
  )
}

export default StatsCardGroup