import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import Footer from '../../components/structure/Footer';
import { useLocation } from 'react-router-dom';

const useStyles = makeStyles<Theme>((theme: Theme) => ({
  root: {
    marginTop: 50,
    overflow: 'hidden'
  },
  container: {
    marginBottom: 80,
  },
  policy: {
    margin: '0px 38px',
    textAlign: 'justify',
    '& a': {
      color: theme.palette.secondary.main,
    },
  },
}));

const TermsAndPrivacy: React.FC = () => {
  const classes = useStyles();
  const location = useLocation();

  return (
    <Grid container spacing={0} className={classes.root}>
      <Grid item xs={1} lg={2} xl={3}></Grid>
      <Grid item xs={10} lg={8} xl={6}>
        <Grid container spacing={5} className={classes.container}>
          {location.pathname.includes('privacy-policy') ? (
            <Grid item xs={12} className={classes.policy}>
              <Typography variant="h2" component="h1">Privacy Policy</Typography>
              <p>Last updated: December 27, 2020</p>
              <p>This Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your information when You use the Service and tells You about Your privacy rights and how the law protects You. </p>
              <p>We take privacy very seriously. If there are any terms in this Privacy Policy that You do not agree with, please discontinue use of our Services immediately.</p>
              <p>We use Your Personal data to provide and improve the Service. By using the Service, You agree to the collection and use of information in accordance with this Privacy Policy, and we appreciate that you are trusting us with your personal information.</p>
              <h1>Interpretation and Definitions</h1>
              <h2>Interpretation</h2>
              <p>The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.</p>
              <h2>Definitions</h2>
              <p>For the purposes of this Privacy Policy:</p>
              <ul>
                <li>
                  <p><strong>You</strong> refers to the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.</p>
                </li>
                <li>
                  <p><strong>Company</strong> (referred to as either &quot;the Company&quot;, &quot;We&quot;, &quot;Us&quot; or &quot;Our&quot; in this Agreement) refers to CodeCache.</p>
                </li>
                <li>
                  <p><strong>Account</strong> means a unique account created for You to access our Service or parts of our Service.</p>
                </li>
                <li>
                  <p><strong>Website</strong> refers to CodeCache, accessible from <a href="https://www.codecache.io" target="_blank" rel="noopener noreferrer" aria-label="CodeCache Website">https://www.codecache.io</a>.</p>
                </li>
                <li>
                  <p><strong>Service</strong> refers to the Website (<a href="https://www.codecache.io" target="_blank" rel="noopener noreferrer" aria-label="CodeCache Website">https://www.codecache.io</a>).</p>
                </li>
                <li>
                  <p><strong>Service Provider</strong> means any natural or legal person who processes the data on behalf of the Company. It refers to third-party companies or individuals employed by the Company to facilitate the Service, to provide the Service on behalf of the Company, to perform services related to the Service or to assist the Company in analyzing how the Service is used.</p>
                </li>
                <li>
                  <p><strong>Personal Data</strong> is any information that relates to an identified or identifiable individual.</p>
                </li>
                <li>
                  <p><strong>Device</strong> means any device that can access the Service such as a computer, a cellphone or a digital tablet.</p>
                </li>
                <li>
                  <p><strong>Usage Data</strong> refers to data collected automatically, either generated by the use of the Service or from the Service infrastructure itself (for example, the duration of a page visit).</p>
                </li>
              </ul>
              <h1>Collecting and Using Your Personal Data</h1>
              <h2>Types of Data Collected</h2>
              <h3>Personal Data</h3>
              <p>While using Our Service, We may ask You to provide Us with certain personally identifiable information that can be used to contact or identify You. Personally identifiable information may include, but is not limited to:</p>
              <ul>
                <li>
                  <p>Username</p>
                </li>
                <li>
                  <p>Email Address</p>
                </li>
                <li>
                  <p>Usage Data</p>
                </li>
              </ul>
              <h3>Usage Data</h3>
              <p>Usage Data is collected automatically when using the Service. This data does not reveal your specific identity (like your name or contact information).</p>
              <p>Usage Data may, however, include information such as Your Device's Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Service that You visit, the time and date of Your visit, the time spent on those pages, unique device identifiers and other diagnostic data.</p>
              <p>When You access the Service by or through a mobile device, We may collect certain information automatically, including, but not limited to, the type of mobile device You use, Your mobile device unique ID, the IP address of Your mobile device, Your mobile operating system, Your location data, unique device identifiers and other diagnostic data.</p>
              <p>We may also collect information that Your browser sends whenever You visit our Service or when You access the Service by or through a mobile device.</p>
              <p>This information is primarily needed to maintain the security and operation of our Website, and for our internal analytics and reporting purposes. </p>
              <h3>Cookies and Other Tracking Technologies</h3>
              <p>We may use cookies and similar tracking technologies (like web beacons and pixels) to access or store information.</p>
              <h2>Use of Your Personal Data</h2>
              <p>The Company may use Personal Data for the following purposes:</p>
              <ul>
                <li><strong>To provide and maintain our Service</strong>, including to monitor the usage of our Service.</li>
                <li><strong>To manage Your Account:</strong> to manage Your registration as a user of the Service. The Personal Data You provide can give You access to different functionalities of the Service that are available to You as a registered user.</li>
                <li><strong>For the performance of a contract:</strong> the development, compliance and undertaking of the purchase contract for the products, items or services You have purchased or of any other contract with Us through the Service.</li>
                <li><strong>To contact You:</strong> To contact You by email other equivalent forms of electronic communication, such as push notifications regarding updates or informative communications related to the functionalities, products or contracted services, including the security updates, when necessary or reasonable for their implementation.</li>
                <li><strong>To manage Your requests:</strong> To attend and manage Your requests to Us.</li>
              </ul>
              <p>We may share your personal information in the following situations:</p>
              <ul>
                <li><strong>With Service Providers:</strong> We may share Your personal information with Service Providers to monitor and analyze the use of our Service, to contact You.</li>
                <li><strong>For Business transfers:</strong> We may share or transfer Your personal information in connection with, or during negotiations of, any merger, sale of Company assets, financing, or acquisition of all or a portion of our business to another company.</li>
                <li><strong>With other users:</strong> If You interact with other users or register through a Third-Party Social Media Service (such as Facebook), Your contacts on the Third-Party Social Media Service may see Your name, profile, pictures and description of Your activity. Similarly, other users will be able to view descriptions of Your activity, communicate with You and view Your profile.</li>
              </ul>
              <h2>Retention of Your Personal Data</h2>
              <p>The Company will retain Your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use Your Personal Data to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes, and enforce ourlegal agreements and policies.</p>
              <p>The Company will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a shorter period of time, except when this data is used to strengthen the security or to improve the functionality of Our Service, or We are legally obligated to retain this data for longer time periods.</p>
              <h2>Transfer of Your Personal Data</h2>
              <p>Your information, including Personal Data, is processed at the Company's operating offices and in any other places where the parties involved in the processing are located. It means that this information may be transferred to — and maintained on — computers located outside of Your state, province, country or other governmental jurisdiction where the data protection laws may differ than those from Your jurisdiction.</p>
              <p>Your consent to this Privacy Policy followed by Your submission of such information represents Your agreement to that transfer.</p>
              <p>The Company will take all steps reasonably necessary to ensure that Your data is treated securely and in accordance with this Privacy Policy and no transfer of Your Personal Data will take place to an organization unless there are adequate controls in place including the security of Your data and other personal information.</p>
              <h2>Disclosure of Your Personal Data</h2>
              <h3>Business Transactions</h3>
              <p>If the Company is involved in a merger, acquisition or asset sale, Your Personal Data may be transferred. We will provide notice before Your Personal Data is transferred and becomes subject to a different Privacy Policy.</p>
              <h3>Law enforcement</h3>
              <p>Under certain circumstances, the Company may be required to disclose Your Personal Data if required to do so by law or in response to valid requests by public authorities (e.g. a court or a government agency).</p>
              <h3>Other legal requirements</h3>
              <p>The Company may disclose Your Personal Data in the good faith belief that such action is necessary to:</p>
              <ul>
                <li>Comply with a legal obligation</li>
                <li>Protect and defend the rights or property of the Company</li>
                <li>Prevent or investigate possible wrongdoing in connection with the Service</li>
                <li>Protect the personal safety of Users of the Service or the public</li>
                <li>Protect against legal liability</li>
              </ul>
              <h2>Security of Your Personal Data</h2>
              <p>The security of Your Personal Data is important to Us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While We strive to use commercially acceptable means to protect Your Personal Data, We cannot guarantee its absolute security.</p>
              <h1>Links to Other Websites</h1>
              <p>Our Service may contain links to other websites that are not operated by Us. If You click on a third party link, You will be directed to that third party's site. We strongly advise You to review the Privacy Policy of every site You visit.</p>
              <p>We have no control over and assume no responsibility for the content, privacy policies or practices of any third party sites or services.</p>
              <h1>Changes to this Privacy Policy</h1>
              <p>We may update our Privacy Policy from time to time. We will notify You of any changes by posting the new Privacy Policy on this page.</p>
              <p>We will let You know via email and/or a prominent notice on Our Service, prior to the change becoming effective and update the &quot;Last updated&quot; date at the top of this Privacy Policy.</p>
              <p>You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.</p>
              <h1>Contact Us</h1>
              <p>If you have any questions about this Privacy Policy, You can contact us:</p>
              <ul>
                <li>
                  <p>By email: <a href="mailto:codecache.contact@gmail.com" rel="noopener" aria-label="Email">codecache.contact@gmail.com</a></p>
                </li>
                <li>
                  <p>By visiting our website: <a href="https://www.codecache.io" rel="noopener noreferrer" target="_blank" aria-label="CodeCache Website">https://www.codecache.io</a></p>
                </li>
              </ul>
            </Grid>
          ) : (
              <Grid item xs={12} className={classes.policy}>
                <Typography variant="h2" component="h1">Terms and Conditions</Typography>
                <p>Last updated: December 27, 2020</p>
                <p>Please read these terms and conditions carefully before using Our Service.</p>
                <p>If there are any terms in these Terms and Conditions that You do not agree with, please discontinue use of our Services immediately.</p>
                <h1>Interpretation and Definitions</h1>
                <h2>Interpretation</h2>
                <p>The words of which the initial letter is capitalized have meanings defined under the following conditions.</p>
                <p>The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.</p>
                <h2>Definitions</h2>
                <p>For the purposes of these Terms and Conditions:</p>
                <ul>
                  <li>
                    <p><strong>Company</strong> (referred to as either &quot;the Company&quot;, &quot;We&quot;, &quot;Us&quot; or &quot;Our&quot; in this Agreement) refers to CodeCache.</p>
                  </li>
                  <li>
                    <p><strong>Country</strong> refers to: Singapore</p>
                  </li>
                  <li>
                    <p><strong>Device</strong> means any device that can access the Service such as a computer, a cellphone or a digital tablet.</p>
                  </li>
                  <li>
                    <p><strong>Service</strong> refers to the Website (<a href="https://www.codecache.io" target="_blank" rel="noopener noreferrer" aria-label="CodeCache Website">https://www.codecache.io</a>).</p>
                  </li>
                  <li>
                    <p><strong>Terms and Conditions</strong> (also referred as &quot;Terms&quot;) mean these Terms and Conditions that form the entire agreement between You and the Company regarding the use of the Service.</p>
                  </li>
                  <li>
                    <p><strong>Website</strong> refers to CodeCache, accessible from <a href="https://www.codecache.io" target="_blank" rel="noopener noreferrer" aria-label="CodeCache Website">https://www.codecache.io</a>.</p>
                  </li>
                  <li>
                    <p><strong>You</strong> means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.</p>
                  </li>
                </ul>
                <h1>Acknowledgement</h1>
                <p>These are the Terms and Conditions governing the use of this Service and the agreement that operates between You and
                the Company. These Terms and Conditions set out the rights and obligations of all users regarding the use of the Service.</p>
                <p>Your access to and use of the Service is conditioned on Your acceptance of and compliance with these Terms and Conditions. These Terms and Conditions apply to all visitors, users and others who access or use the Service.</p>
                <p>By accessing or using the Service You agree to be bound by these Terms and Conditions. If You disagree with any part of these Terms and Conditions then You may not access the Service.</p>
                <p>Your access to and use of the Service is also conditioned on Your acceptance of and compliance with the <a href="/privacy-policy" target="_blank" rel="noopener noreferrer" aria-label="CodeCache Privacy Policy">Privacy Policy</a> of the Company. Our <a href="/privacy-policy" target="_blank" rel="noopener noreferrer" aria-label="CodeCache Privacy Policy">Privacy Policy</a> describes Our policies and procedures on the collection, use and
                disclosure of Your personal information when You use the Application or the Website and tells You about Your privacy rights and how the law protects You. Please read Our <a href="/privacy-policy" target="_blank" rel="noopener noreferrer" aria-label="CodeCache Privacy Policy">Privacy Policy</a> carefully before using Our Service.</p>
                <h1>Links to Other Websites</h1>
                <p>Our Service may contain links to third-party web sites or services that are not owned or controlled by the Company.</p>
                <p>The Company has no control over, and assumes no responsibility for, the content, privacy policies, or practices of
                any third party web sites or services. You further acknowledge and agree that the Company shall not be responsible
                or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with
                the use of or reliance on any such content, goods or services available on or through any such web sites or services.</p>
                <p>We strongly advise You to read the terms and conditions and privacy policies of any third-party web sites or services that You visit.</p>
                <h1>Termination</h1>
                <p>We may terminate or suspend Your access immediately, without prior notice or liability, for any reason whatsoever, including without limitation if You breach these Terms and Conditions.</p>
                <p>Upon termination, Your right to use the Service will cease immediately.</p>
                <h1>Limitation of Liability</h1>
                <p>To the maximum extent permitted by applicable law, in no event shall the Company or its suppliers be liable for any
                special, incidental, indirect, or consequential damages whatsoever (including, but not limited to, damages for loss
                of profits, loss of data or other information, for business interruption, for personal injury, loss of privacy
                arising out of or in any way related to the use of or inability to use the Service, third-party software and/or
                third-party hardware used with the Service, or otherwise in connection with any provision of this Terms), even if
                the Company or any supplier has been advised of the possibility of such damages and even if the remedy fails of its essential purpose.</p>
                <h1>&quot;AS IS&quot; and &quot;AS AVAILABLE&quot; Disclaimer</h1>
                <p>The Service is provided to You &quot;AS IS&quot; and &quot;AS AVAILABLE&quot; and with all faults and defects without
                warranty of any kind. To the maximum extent permitted under applicable law, the Company, on its own behalf and on
                behalf of its Affiliates and its and their respective licensors and service providers, expressly disclaims all
                warranties, whether express, implied, statutory or otherwise, with respect to the Service, including all implied
                warranties of merchantability, fitness for a particular purpose, title and non-infringement, and warranties that may
                arise out of course of dealing, course of performance, usage or trade practice. Without limitation to the foregoing,
                the Company provides no warranty or undertaking, and makes no representation of any kind that the Service will meet
                Your requirements, achieve any intended results, be compatible or work with any other software, applications,
                systems or services, operate without interruption, meet any performance or reliability standards or be error free or that any errors or defects can or will be corrected.</p>
                <p>Without limiting the foregoing, neither the Company nor any of the company's provider makes any representation or
                warranty of any kind, express or implied: (i) as to the operation or availability of the Service, or the
                information, content, and materials or products included thereon; (ii) that the Service will be uninterrupted or
                error-free; (iii) as to the accuracy, reliability, or currency of any information or content provided through the
                Service; or (iv) that the Service, its servers, the content, or e-mails sent from or on behalf of the Company are free of viruses, scripts, trojan horses, worms, malware, timebombs or other harmful components.</p>
                <p>Some jurisdictions do not allow the exclusion of certain types of warranties or limitations on applicable statutory
                rights of a consumer, so some or all of the above exclusions and limitations may not apply to You. But in such a
                case the exclusions and limitations set forth in this section shall be applied to the greatest extent enforceable under applicable law.</p>
                <h1>Governing Law</h1>
                <p>The laws of the Country, excluding its conflicts of law rules, shall govern this Terms and Your use of the Service. Your use of the Application may also be subject to other local, state, national, or international laws.</p>
                <h1>Disputes Resolution</h1>
                <p>If You have any concern or dispute about the Service, You agree to first try to resolve the dispute informally by contacting the Company.</p>
                <h1>Severability and Waiver</h1>
                <h2>Severability</h2>
                <p>If any provision of these Terms is held to be unenforceable or invalid, such provision will be changed and
                interpreted to accomplish the objectives of such provision to the greatest extent possible under applicable law and the remaining provisions will continue in full force and effect.</p>
                <h2>Waiver</h2>
                <p>Except as provided herein, the failure to exercise a right or to require performance of an obligation under this
                Terms shall not effect a party's ability to exercise such right or require such performance at any time thereafter nor shall be the waiver of a breach constitute a waiver of any subsequent breach.</p>
                <h1>Translation Interpretation</h1>
                <p>These Terms and Conditions may have been translated if We have made them available to You on our Service. You agree that the original English text shall prevail in the case of a dispute.</p>
                <h1>Changes to These Terms and Conditions</h1>
                <p>We reserve the right, at Our sole discretion, to modify or replace these Terms at any time. If a revision is material
                We will make reasonable efforts to provide at least 30 days' notice prior to any new terms taking effect. What constitutes a material change will be determined at Our sole discretion.</p>
                <p>By continuing to access or use Our Service after those revisions become effective, You agree to be bound by the
                revised terms. If You do not agree to the new terms, in whole or in part, please stop using the website and the Service.</p>
                <h1>Contact Us</h1>
                <p>If you have any questions about these Terms and Conditions, You can contact us:</p>
                <ul>
                  <li>
                    <p>By email: <a href="mailto:codecache.contact@gmail.com" rel="noopener" aria-label="Email">codecache.contact@gmail.com</a></p>
                  </li>
                  <li>
                    <p>By visiting our website: <a href="https://www.codecache.io" rel="noopener noreferrer" target="_blank" aria-label="CodeCache Website">https://www.codecache.io</a></p>
                  </li>
                </ul>
              </Grid>
            )}
        </Grid>
        <Footer />
      </Grid>
      <Grid item xs={1} lg={2} xl={3}></Grid>
    </Grid >
  )
}

export default TermsAndPrivacy
