import React, { Fragment, useEffect, useState } from 'react';
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import { Accordion, AccordionDetails, AccordionSummary, Avatar, Button, Card, CardContent, CardHeader, CircularProgress, Collapse, FormControl, Grid, Hidden, IconButton, InputAdornment, InputLabel, List, ListItem, ListItemAvatar, ListItemText, MenuItem, Select, TextField, Tooltip, Typography } from '@material-ui/core';
import { AddBox, AddCircle, Close, Delete, Edit, ExpandMore, ReportProblemRounded, Search, Tune } from '@material-ui/icons';
import LoadingAnimation from '../utility/LoadingAnimation';
import NewCollectionForm from '../forms/NewCollectionForm';
import { DialogFeatures, GenericResponseLoadState, initialDialogFeatures, initialGenericResponse, initialSnippetSearchList, Options, Snippet, SnippetCollection, SnippetCollectionListLoadState, SnippetSearchListLoadState } from '../../@types';
import DialogAlert from '../utility/DialogAlert';
import { datetimeToDateString, getModeIcon, useCallApi, useInfiniteScrollingObserver } from '../../@utils';
import { MODES, vw } from '../../@constants';
import CodeEditorCard from './CodeEditorCard';
import NewSnippetForm from '../forms/NewSnippetForm';
import EditCollectionForm from '../forms/EditCollectionForm';
import ResponseSnackbar from '../utility/ResponseSnackbar';

const useStyles = makeStyles((theme: Theme) => ({
  snippetListCard: {
    height: '100%',
    overflow: 'hidden',
    '& .MuiGrid-root': {
      height: '100%',
      '& .MuiCard-root': {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        '& .MuiCardHeader-action': {
          marginTop: 0,
        },
        '& .MuiCardContent-root': {
          flex: '1 1 1px',
          overflow: 'scroll',
          paddingTop: 0,
          marginTop: 16
        },
      },
      '& .MuiListItem-root': {
        wordBreak: 'break-word',
      },
    },
  },
  inputGroup: {
    padding: '16px 16px 0 16px'
  },
  noSnippets: {
    '& .MuiTypography-root': {
      textAlign: 'center',
    },
  },
  emptyContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
  },
  filterGroup: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 20,
  },
  menuPaper: {
    maxHeight: 315,
  },
  formControl: {
    width: '48%',
    textAlign: 'center'
  },
  accordion: {
    backgroundColor: theme.palette.background.default,
    '& .MuiIconButton-root': {
      padding: 5
    },
    '& .MuiAccordionSummary-content': {
      overflow: 'hidden',
    },
    '& #headerSection': {
      display: 'flex',
      width: '100%',
      alignItems: 'center',
      justifyContent: 'space-between',
      '& span': {
        wordBreak: 'break-word',
      },
      '& #buttonGroup': {
        display: 'flex',
        justifyContent: 'flex-end',
      },
    },
    '& .MuiList-root': {
      width: '100%',
    },
  },
  nestedSnippetItem: {
    paddingLeft: theme.spacing(4),
  },
}));

interface SnippetCollectionListCardProps {
  userID: number;
  snippetCollectionListLoadState: SnippetCollectionListLoadState;
  snippetCollectionListOptions: Options;
  snippetCollectionList: Array<SnippetCollection>;
  setSnippetCollectionList: React.Dispatch<React.SetStateAction<SnippetCollection[]>>;
  snippetView: Snippet;
  setSnippetView: React.Dispatch<React.SetStateAction<Snippet>>;
  editingFlag: boolean;
  setEditingFlag: React.Dispatch<React.SetStateAction<boolean>>;
  setSnippetCollectionPage: React.Dispatch<React.SetStateAction<number>>;
}

const SnippetCollectionListCard: React.FC<SnippetCollectionListCardProps> = (
  { userID,
    snippetCollectionListLoadState,
    snippetCollectionListOptions,
    snippetCollectionList,
    setSnippetCollectionList,
    snippetView,
    setSnippetView,
    editingFlag, 
    setEditingFlag,
    setSnippetCollectionPage
  }: SnippetCollectionListCardProps) => {
  const classes = useStyles();
  const theme = useTheme();

  // Snackbar Handle Success for Dialogs
  const [message, setMessage] = useState<string>('');

  // Handle New Collection
  const [dialogFeatures, setDialogFeatures] = useState<DialogFeatures>(initialDialogFeatures);
  const handleNewCollection = () => {
    setDialogFeatures({
      open: true,
      title: 'Add Collection',
      color: `${theme.palette.primary.main}80`,
      customBodyActions: <NewCollectionForm
        userID={userID}
        triggerClose={() => {
          setDialogFeatures(initialDialogFeatures)
        }}
        setMessage={setMessage}
      />,
    });
  };

  // Handle New Snippet
  const handleNewSnippet = (collectionID: number) => {
    setDialogFeatures({
      open: true,
      title: 'Add Code Snippet',
      width: 600,
      color: `${theme.palette.primary.main}80`,
      customBodyActions: <NewSnippetForm
        userID={userID}
        triggerClose={() => setDialogFeatures(initialDialogFeatures)}
        setMessage={setMessage}
        defaultCollectionID={collectionID}
      />,
    });
  };

  // Handle Edit Collection
  const handleEditCollection = (collectionID: number, collectionName: string) => {
    setDialogFeatures({
      open: true,
      title: 'Edit Collection',
      color: `${theme.palette.primary.main}80`,
      customBodyActions: <EditCollectionForm
        userID={userID}
        triggerClose={() => {
          setDialogFeatures(initialDialogFeatures)
        }}
        setMessage={setMessage}
        collectionID={collectionID}
        collectionName={collectionName}
      />,
    });
  };

  // Handle Delete Collection
  const [deleteCollectionLoadState, deleteCollectionOptions]: [GenericResponseLoadState, Options] = useCallApi(
    'collection/delete/',
    initialGenericResponse,
    'DELETE',
    false,
    'PRIVATE'
  );

  const handleDeleteCollection = (collectionID: number) => {
    setDialogFeatures({
      open: true,
      title: 'Are you sure? This action cannot be undone',
      icon: <ReportProblemRounded />,
      color: `${theme.palette.error.main}80`,
      message: 'This collection and ALL its snippets will be permanently deleted',
      primaryAction: {
        buttonText: 'Yes, Delete',
        buttonFunction: () => {
          deleteCollectionOptions.setQueryParams(collectionID.toString());
        }
      },
      cancelButton: true,
    });
  };

  useEffect(() => {
    if (deleteCollectionLoadState.data.success) {
      setMessage('Collection Successfully Deleted!');
      window.location.reload();
    } else {
      return
    }
  }, [deleteCollectionLoadState]);

  // Handle Collections Infinite Scrolling
  const [lastCollectionElementRef] = useInfiniteScrollingObserver(snippetCollectionListLoadState, snippetCollectionListOptions, setSnippetCollectionPage);

  useEffect(() => {
    setSnippetCollectionList(prevSnippetCollectionList => {
      //If current data is dummy data or new data is a page 1 data, clear the old data
      if (prevSnippetCollectionList[0]?.collection_id === 0 || snippetCollectionListLoadState.data.metadata.current_page === 1) prevSnippetCollectionList = []
      return [...prevSnippetCollectionList, ...snippetCollectionListLoadState.data.results]
    });
  }, [snippetCollectionListLoadState.data.results, snippetCollectionListLoadState.data.metadata.current_page, setSnippetCollectionList]);

  // Handle List Accordion
  const [expanded, setExpanded] = React.useState<number | false>(0);

  const handleChange = (panelIndex: number) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
    setExpanded(isExpanded ? panelIndex : false);
  };

  // Handle Search and Filter
  const [searchMode, setSearchMode] = useState<boolean>(false);
  const [query, setQuery] = useState<string>('');
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [mode, setMode] = useState<string>('all');
  const [sort, setSort] = useState<string>('recent');
  const [snippetSearchPage, setSnippetSearchPage] = useState<number>(1);

  const [snippetSearchListLoadState, snippetSearchListOptions]: [SnippetSearchListLoadState, Options] = useCallApi(
    `snippet/all/search/${userID}/?page=${snippetSearchPage}&sort=${sort}${!!mode ? `&mode=${mode}` : ''}&q=${query}`,
    initialSnippetSearchList,
    'GET',
    true,
    'PRIVATE'
  );

  const [snippetSearchList, setSnippetSearchList] = useState<Array<Snippet>>(snippetSearchListLoadState.data.results);

  // Wait until userID is obtained before loading snippetList
  useEffect(() => {
    if (userID !== 0) snippetSearchListOptions.setShouldFetch(true);
  }, [userID, snippetSearchListOptions]);

  // Handle Snippet Search Infinite Scrolling
  const [lastSnippetElementRef] = useInfiniteScrollingObserver(snippetSearchListLoadState, snippetSearchListOptions, setSnippetSearchPage);

  useEffect(() => {
    setSnippetSearchList(prevSnippetSearchList => {
      //If current data is dummy data or new data is a page 1 data, clear the old data
      if (prevSnippetSearchList[0]?.collection_id === 0 || snippetSearchListLoadState.data.metadata.current_page === 1) prevSnippetSearchList = []
      return [...prevSnippetSearchList, ...snippetSearchListLoadState.data.results]
    });
  }, [snippetSearchListLoadState.data.results, snippetSearchListLoadState.data.metadata.current_page]);

  const createSnippetListItems = (snippetList: Array<Snippet>, nested: boolean, lastRef?: any) => {
    return snippetList.map((snippet: Snippet, index: number) => {
      return <ListItem
        button
        className={nested ? classes.nestedSnippetItem : undefined}
        key={snippet.id}
        selected={vw >= 960 && snippetView.id === snippet.id}
        onClick={() => {
          if (vw >= 960) {
            if (editingFlag) {
              setDialogFeatures({
                open: true,
                title: 'Are you sure? You have unsaved changes',
                icon: <ReportProblemRounded />,
                color: `${theme.palette.error.main}80`,
                message: 'Your changes will be deleted',
                primaryAction: {
                  buttonText: 'Yes, Delete',
                  buttonFunction: () => {
                    setSnippetView(snippet);
                    setEditingFlag(false);
                    setDialogFeatures(initialDialogFeatures);
                  }
                },
                cancelButton: true,
              });
            } else {
              setSnippetView(snippet);
            }
          } else {
            setMobileViewDialogFeatures({
              open: true,
              color: `${theme.palette.primary.main}80`,
              width: '90vw',
              customBodyActions: <CodeEditorCard
                userID={userID}
                isLoading={snippetCollectionListLoadState.isLoading} // Do not reload while searching, use initial snippetList loading state instead
                snippetView={snippet}
                editingFlag={false}
                setEditingFlag={() => { }} // mobile does not need editing flag (no unsaved changes warning)
                triggerClose={() => {
                  setMobileViewDialogFeatures(initialDialogFeatures)
                }}
              />,
            });
          }
        }}
        ref={lastRef && snippetList.length === index + 1 ? lastRef : null}
      >
        <ListItemAvatar><Avatar variant='square' src={getModeIcon(snippet.programming_language)} alt={snippet.programming_language} /></ListItemAvatar>
        <ListItemText primary={snippet.snippet_name} secondary={datetimeToDateString(snippet.date_created)} />
      </ListItem>
    })
  };

  // Handle mobile view
  const [mobileViewDialogFeatures, setMobileViewDialogFeatures] = useState<DialogFeatures>(initialDialogFeatures);

  return (
    <Grid container spacing={0} className={classes.snippetListCard}>
      <Grid item xs={12}>
        <Card>
          <CardHeader
            title={searchMode ? 'Snippets' : 'Collections'}
            action={
              <Fragment>
                {searchMode ? (
                  <Tooltip title="Filter Snippets">
                    <IconButton onClick={() => setShowFilter(prev => !prev)}>
                      <Tune />
                    </IconButton>
                  </Tooltip>
                ) : (
                    <Tooltip title="Add Collection">
                      <IconButton onClick={handleNewCollection}>
                        <AddCircle />
                      </IconButton>
                    </Tooltip>
                  )}
                <IconButton
                  onClick={() => {
                    setQuery('');
                    setMode('all');
                    setSort('recent');
                    setSearchMode(prev => !prev);
                    setShowFilter(false);
                  }}
                >
                  {searchMode ? <Close /> : <Search />}
                </IconButton>
              </Fragment>
            }
          />
          {searchMode &&
            <form className={classes.inputGroup}>
              <TextField
                fullWidth
                id="search"
                label="Search"
                variant="outlined"
                // color="secondary"
                value={query}
                onChange={(e) => {
                  setSnippetSearchPage(1);
                  setQuery(e.target.value);
                  e.persist();
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <Search />
                    </InputAdornment>
                  ),
                  endAdornment: query && (
                    <Fragment>
                      {snippetSearchListLoadState.isLoading ? <CircularProgress color="secondary" size={20} /> : null}
                      <Button onClick={() => setQuery('')}>
                        Clear
                        </Button>
                    </Fragment>
                  )
                }}
              />
              <Collapse in={showFilter} timeout="auto" unmountOnExit>
                <div className={classes.filterGroup}>
                  <FormControl variant="outlined" className={classes.formControl}>
                    <InputLabel id="language-select">Mode</InputLabel>
                    <Select
                      labelId="mode-select"
                      variant="outlined"
                      value={mode}
                      onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                        setMode(event.target.value as string);
                      }}
                      label="Mode"
                      MenuProps={{ classes: { paper: classes.menuPaper } }}
                    >
                      <MenuItem value='all'>All</MenuItem>
                      {MODES.map((mode) => {
                        return <MenuItem key={mode.value} value={mode.value}>{mode.name}</MenuItem>
                      })}
                    </Select>
                  </FormControl>
                  <FormControl variant="outlined" className={classes.formControl}>
                    <InputLabel id="sort-select">Sort</InputLabel>
                    <Select
                      labelId="sort-select"
                      variant="outlined"
                      value={sort}
                      onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                        setSort(event.target.value as string);
                      }}
                      label="Sort"
                    >
                      <MenuItem value='recent'>Recent</MenuItem>
                      <MenuItem value='oldest'>Oldest</MenuItem>
                      <MenuItem value='asc'>Name Ascending</MenuItem>
                      <MenuItem value='desc'>Name Descending</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </Collapse>
            </form>
          }
          <CardContent>
            {searchMode ? (
              <Fragment>
                <List>
                  {snippetSearchList.length === 0 ? (
                    <ListItem>
                      <ListItemText
                        className={classes.noSnippets}
                      >
                        <ListItemText primary={<em>No Snippets Found</em>} primaryTypographyProps={{ variant: 'subtitle2' }} />
                      </ListItemText>
                    </ListItem>
                  ) : (
                      <Fragment>
                        {createSnippetListItems(snippetSearchList, false, lastSnippetElementRef)}
                      </Fragment>
                    )}
                </List>
                {snippetSearchListLoadState.isLoading && <LoadingAnimation />}
              </Fragment>
            ) : (
                <Fragment>
                  {snippetCollectionListLoadState.isError ? (
                    <div className={classes.emptyContainer}>
                      <span>Something went wrong while loading your snippets</span>
                    </div>
                  ) : (
                      <Fragment>
                        {snippetCollectionList.map((snippetCollection: SnippetCollection, index: number) => {
                          if (snippetCollection.collection_id === 0) {
                            return <Fragment key={index} /> // If collection is dummy data, return message
                          } else {
                            return <Accordion className={classes.accordion} expanded={expanded === index} onChange={handleChange(index)} key={index}>
                              <AccordionSummary
                                expandIcon={<ExpandMore />}
                                ref={snippetCollectionList.length === index + 1 ? lastCollectionElementRef : null}
                              >
                                <div id="headerSection">
                                  <Typography component='span' variant='subtitle1'>
                                    {snippetCollection.collection_name}
                                  </Typography>
                                  {expanded === index &&
                                    <div id="buttonGroup">
                                      <Tooltip title="Add Snippet">
                                        <IconButton onClick={() => handleNewSnippet(snippetCollection.collection_id)}>
                                          <AddBox />
                                        </IconButton>
                                      </Tooltip>
                                      <Tooltip title="Edit Collection">
                                        <IconButton onClick={() => handleEditCollection(snippetCollection.collection_id, snippetCollection.collection_name)}>
                                          <Edit />
                                        </IconButton>
                                      </Tooltip>
                                      <Tooltip title="Delete Collection">
                                        <IconButton onClick={() => handleDeleteCollection(snippetCollection.collection_id)}>
                                          <Delete />
                                        </IconButton>
                                      </Tooltip>
                                    </div>
                                  }
                                </div>
                              </AccordionSummary>
                              <AccordionDetails>
                                <List>
                                  {snippetCollection.snippets.length === 0 ? (
                                    <ListItem>
                                      <ListItemText
                                        className={classes.noSnippets}
                                      >
                                        <ListItemText primary={<em>No Snippets</em>} primaryTypographyProps={{ variant: 'subtitle2' }} />
                                      </ListItemText>
                                    </ListItem>
                                  ) : (
                                      <Fragment>
                                        {createSnippetListItems(snippetCollection.snippets, true, null)}
                                      </Fragment>
                                    )}
                                </List>
                              </AccordionDetails>
                            </Accordion>
                          }
                        })}
                      </Fragment>
                    )}
                  {snippetCollectionListLoadState.isLoading && <LoadingAnimation />}
                </Fragment>
              )}
          </CardContent>
        </Card>
      </Grid >
      <DialogAlert dialogFeatures={dialogFeatures} setDialogFeatures={setDialogFeatures} />
      <Hidden mdUp>
        <DialogAlert dialogFeatures={mobileViewDialogFeatures} setDialogFeatures={setMobileViewDialogFeatures} />
      </Hidden>
      <ResponseSnackbar message={message} setMessage={setMessage} severity='success' />
    </Grid >
  )
}

export default SnippetCollectionListCard
