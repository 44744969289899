import React, { Fragment } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import PatreonLogo from '../../assets/images/patreon/Digital-Patreon-Logo.png';
import { Button, useScrollTrigger, Zoom } from '@material-ui/core';

const useStyles = makeStyles<Theme>((theme: Theme) => ({
  container: {
    position: 'fixed',
    bottom: theme.spacing(5),
    right: theme.spacing(5),
  },
  patreonButton: {
    display: 'inline-block',
    '& #patreon': {
      animation: '$pulse 1.5s ease infinite',
      backgroundColor: '#F96654',
      maxWidth: 52,
      borderRadius: 30,
      minWidth: 0,
      justifyContent: 'flex-start',
      '-webkit-transition': 'max-width 1s',
      transition: 'max-width 1s',
      '& .MuiButton-startIcon': {
        marginLeft: -10,
      },
      '& #buttonText': {
        maxWidth: 0,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        display: 'flex',
        alignItems: 'center',
        color: '#ffffff',
        '-webkit-transition': 'max-width 1s',
        transition: 'max-width 1s',
      },
    },
    '& #patreon:hover': {
      animation: 'none',
      maxWidth: 205,
      '& #buttonText': {
        maxWidth: '100%',
      },
    },
  },
  '@keyframes pulse': {
    '0%': {
      transform: 'scale(1.2)',
    },
    '70%': {
      transform: 'scale(1)',
    },
    '100%': {
      transform: 'scale(1.2)',
    }
  },
  '@media only screen and (max-width: 600px)': {
    container: {
      position: 'fixed',
      bottom: theme.spacing(3),
      right: theme.spacing(3),
    },
    patreonButton: {
      '& #patreon': {
        maxWidth: 52,
        animation: 'none',
        '& #buttonText': {
          maxWidth: 0,
        },
      },
      '& #patreon:hover': {
        maxWidth: 52,
        '& #buttonText': {
          maxWidth: 0,
        },
      },
    },
  }
}));

interface PatreonExpandingButtonWithContactProps {
  floating: boolean;
}

const PatreonExpandingButtonWithContact: React.FC<PatreonExpandingButtonWithContactProps> = ({ floating }: PatreonExpandingButtonWithContactProps) => {
  const classes = useStyles();

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 500,
  });

  const ExpandingButton = <span className={classes.patreonButton}>
    <Button
      variant="contained"
      id="patreon"
      href='https://www.patreon.com/leonardtng'
      target='_blank'
      startIcon={<img src={PatreonLogo} alt="Patreon Logo" height="40px" width="40px" />}
      aria-label="Patreon"
      rel="noopener"
    >
      <div id="buttonText">Become a Patron!</div>
    </Button>
  </span>

  return (
    <Fragment>
      {floating ? (
          <Zoom in={!trigger} style={{ transitionDelay: !trigger ? '500ms' : '0ms' }}>
            <div className={classes.container}>
              {ExpandingButton}
            </div>
          </Zoom>
      ) : (
          ExpandingButton
        )}
    </Fragment>
  )
}

export default PatreonExpandingButtonWithContact
