import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Avatar, Card, CardContent, Grow, Typography } from '@material-ui/core';
import { CountUpAnimation } from '../utility/CountUpAnimation';
import { Skeleton } from '@material-ui/lab';

const useStyles = makeStyles((theme: Theme) => ({
  statsSummary: {
    display: 'flex',
    justifyContent: 'center',
    '& #inner': {
      width: '90%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-evenly',
      '& #primary': {
        backgroundColor: `${theme.palette.primary.main}10`,
      },
      '& #secondary': {
        backgroundColor: `${theme.palette.secondary.main}10`,
      },
      '& .MuiAvatar-root': {
        padding: '1.8rem',
        '& .MuiSvgIcon-root': {
          fontSize: '2.2rem',
        },
      },
    },
  },
}));

interface SmallStatsCardProps {
  isLoading: boolean;
  icon: React.ReactNode;
  color: string;
  info: number | string;
  label: string;
}

const SmallStatsCard: React.FC<SmallStatsCardProps> = ({ isLoading, icon, color, info, label }: SmallStatsCardProps) => {
  const classes = useStyles();

  return (
    <Grow in timeout={1500}>
      <Card>
        <CardContent className={classes.statsSummary}>
          <div id="inner">
            <Avatar id={color}>
              {icon}
            </Avatar>
            <div>
              {isLoading ? (
                <Skeleton animation="wave" height={56} />
              ) : (
                  <Typography variant="h3" component="h3">
                    <CountUpAnimation>
                      {info}
                    </CountUpAnimation>
                  </Typography>
                )}
              <Typography variant="subtitle1" component="span" color="textSecondary">
                {label}
              </Typography>
            </div>
          </div>
        </CardContent>
      </Card>
    </Grow>
  )
}

export default SmallStatsCard