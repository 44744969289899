export const getModeName = (mode: string) => {
  switch (mode) {
    case 'javascript':
      return 'JavaScript';
    case 'typescript':
      return 'TypeScript';
    case 'python':
      return 'Python';
    case 'java':
      return 'Java';
    case 'r':
      return 'R';
    case 'golang':
      return 'Go';
    case 'c_cpp':
      return 'C / C++'
    case 'csharp':
      return 'C#';
    case 'swift':
      return 'Swift';
    case 'php':
      return 'PHP';
    case 'ruby':
      return 'Ruby';
    case 'ocaml':
      return 'OCaml';
    case 'scala':
      return 'Scala';
    case 'haskell':
      return 'Haskell';
    case 'julia':
      return 'Julia';
    case 'matlab':
      return 'MATLAB';
    case 'jsx':
      return 'React JavaScript';
    case 'tsx':
      return 'React TypeScript';
    case 'html':
      return 'HTML';
    case 'css':
      return 'CSS';
    case 'dockerfile':
      return 'Dockerfile';
    case 'gitignore':
      return 'Gitignore';
    case 'markdown':
      return 'Markdown';
    case 'plain_text':
      return 'Plain Text';
    case 'mysql':
      return 'MySQL';
    case 'json':
      return 'JSON';
  }
  return ''
}