import React, { useEffect, useState } from 'react';
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import { Button, Grid, Typography } from '@material-ui/core';
import Success from '../../../assets/images/illustrations/success.svg';
import LoginForm from '../../../components/forms/LoginForm';
import { DialogFeatures, initialDialogFeatures } from '../../../@types';
import DialogAlert from '../../../components/utility/DialogAlert';
import Confetti from 'react-dom-confetti';
import Footer from '../../../components/structure/Footer';

const useStyles = makeStyles<Theme>((theme: Theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    overflow: 'hidden',
  },
  message: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '75vh',
    '& img': {
      height: 300,
      width: 300,
    },
  },
  button: {
    marginTop: 50
  },
  footer: {
    position: 'absolute',
    bottom: 10,
    width: '100%',
  },
  '@media only screen and (max-width: 600px)': {
    message: {
      '& img': {
        height: 150,
        width: 150,
      },
    },
    button: {
      marginTop: 20
    },
    footer: {
      position: 'relative',
    },
  },
}));

const VerificationSuccess = () => {
  const classes = useStyles();
  const theme = useTheme();

  const [dialogFeatures, setDialogFeatures] = useState<DialogFeatures>(initialDialogFeatures);

  const handleLogin = () => {
    setDialogFeatures({
      open: true,
      title: 'Login',
      color: `${theme.palette.primary.main}80`,
      customBodyActions: <LoginForm triggerClose={() => setDialogFeatures(initialDialogFeatures)} />,
    })
  };

  const confettiConfig = {
    angle: 0,
    spread: 500,
    startVelocity: 40,
    elementCount: 200,
    dragFriction: 0.12,
    duration: 6000,
    stagger: 3,
    width: "10px",
    height: "10px",
    perspective: "500px",
    colors: ["#a864fd", "#29cdff", "#78ff44", "#ff718d", "#fdff6a"]
  };

  const [confettiActive, setConfettiActive] = useState<boolean>(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setConfettiActive(true);
    }, 500);
    return () => clearTimeout(timer);
  }, []);

  return (
    <Grid container spacing={0} className={classes.root}>
      <Grid item xs={1} lg={2} xl={3} />
      <Grid item xs={10} lg={8} xl={6} className={classes.message}>
        <Typography variant="h3" gutterBottom>Success!</Typography>
        <Confetti active={confettiActive} config={confettiConfig} />
        <img src={Success} alt="Success" />
        <Typography variant="h6" gutterBottom>Thank you for verifying your email. You can now login into your account.</Typography>
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={handleLogin}
        >
          Login now!
        </Button>
      </Grid>
      <Grid item xs={1} lg={2} xl={3} />
      <Grid item xs={1} lg={2} xl={3} />
      <Grid item xs={10} lg={8} xl={6} className={classes.footer}>
        <Footer />
      </Grid>
      <Grid item xs={1} lg={2} xl={3} />
      <DialogAlert dialogFeatures={dialogFeatures} setDialogFeatures={setDialogFeatures} />
    </Grid>
  )
}

export default VerificationSuccess
