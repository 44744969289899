export interface ErrorObjectInterface {
  error: boolean;
  message: string;

  // Registration/Login Error Types
  username?: Array<string>;
  email?: Array<string>;
  first_name?: Array<string>;
  last_name?: Array<string>;
  password?: Array<string>;
  non_field_errors?: Array<string>;

  // Change Password Data Types
  old_password?: Array<string>;
  new_password?: Array<string>;
};

export const initialErrorObject: ErrorObjectInterface = {
  error: false,
  message: ''
};
