import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Typography, Card, CardHeader, CardContent, Avatar } from '@material-ui/core';

const useStyles = makeStyles<Theme>((theme: Theme) => ({
  root: {
    width: '100%',
    '& .MuiCardContent-root': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    '& .MuiAvatar-root': {
      marginTop: 20,
      marginBottom: 50,
      height: 200,
      width: 200,
    },
  },
}));

interface FeatureCardProps {
  title: string;
  image: string;
  bodyText: string;
}

const FeatureCard: React.FC<FeatureCardProps> = ({ title, image, bodyText }: FeatureCardProps) => {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardHeader title={title} />
      <CardContent>
        <Avatar src={image} variant="rounded" alt={title} />
        <Typography variant="body1" component="p">{bodyText}</Typography>
      </CardContent>
    </Card>
  )
}

export default FeatureCard