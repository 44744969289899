import React, { useContext } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Button, Card, CardContent, CardHeader, FormControl, Grid, MenuItem, Select } from '@material-ui/core';
import SettingsInnerGridLayout from '../layouts/SettingsInnerGridLayout';
import { MODES, THEMES } from '../../@constants';
import { UserDefaultsContext } from '../../context/UserDefaultsContext';

const useStyles = makeStyles((theme: Theme) => ({
  formControl: {
    width: '100%',
    textAlign: 'center',
  },
  menuPaper: {
    maxHeight: 315,
  },
  saveButton: {
    textAlign: 'center',
  },
}));

interface PreferencesSettingsCardProps {
  initialDefaultMode: string;
  initialDefaultTheme: string;
}

const PreferencesSettingsCard: React.FC<PreferencesSettingsCardProps> = ({ initialDefaultMode, initialDefaultTheme }: PreferencesSettingsCardProps) => {
  const classes = useStyles();
  const { defaultMode, setDefaultMode, defaultTheme, setDefaultTheme, handleSaveChanges } = useContext(UserDefaultsContext);

  return (
    <Card>
      <CardHeader
        title="Preferences"
      />
      <CardContent>
        <Grid container spacing={0}>
          <SettingsInnerGridLayout
            title="Default Mode"
            component={
              <FormControl variant="outlined" className={classes.formControl}>
                <Select
                  variant="outlined"
                  value={defaultMode}
                  onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                    setDefaultMode(event.target.value as string);
                  }}
                  MenuProps={{ classes: { paper: classes.menuPaper } }}
                >
                  {MODES.map((mode) => {
                    return <MenuItem key={mode.value} value={mode.value}>{mode.name}</MenuItem>
                  })}
                </Select>
              </FormControl>
            }
          />
          <SettingsInnerGridLayout
            title="Default Theme"
            component={
              <FormControl variant="outlined" className={classes.formControl}>
                <Select
                  variant="outlined"
                  value={defaultTheme}
                  onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                    setDefaultTheme(event.target.value as string);
                  }}
                  MenuProps={{ classes: { paper: classes.menuPaper } }}
                >
                  {THEMES.map((theme) => {
                    return <MenuItem key={theme.value} value={theme.value}>{theme.name}</MenuItem>
                  })}
                </Select>
              </FormControl>
            }
          />
          <Grid item xs={12} className={classes.saveButton}>
            <Button
              variant="contained"
              onClick={handleSaveChanges}
              color="primary"
              disabled={defaultMode === initialDefaultMode && defaultTheme === initialDefaultTheme}
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

export default PreferencesSettingsCard
