import React, { useLayoutEffect, useState } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import NavBar from '../components/structure/NavBar';
import { Grid } from '@material-ui/core';
import { Switch, Route } from 'react-router-dom';
import Landing from './home/Landing';
import Dashboard from './private/Dashboard';
import VerificationError from './home/verification/VerificationError';
import VerificationSuccess from './home/verification/VerificationSuccess';
import { BeforeInstallPromptEvent } from '../@types';
import TermsAndPrivacy from './home/TermsAndPrivacy';

const useStyles = makeStyles<Theme>((theme: Theme) => ({
  root: {
    overflowX: 'hidden',
    display: 'flex',
  },
  toolbar: theme.mixins.toolbar,
}));

const Main: React.FC = () => {
  const classes = useStyles();
  const [mobileOpen, setMobileOpen] = useState<boolean>(false);
  const [userID, setUserID] = useState<number>(0);

  // Handle User Prompt Install PWA
  const [supportsPWA, setSupportsPWA] = useState(false);
  const [promptInstall, setPromptInstall] = useState<BeforeInstallPromptEvent | null>(null);

  useLayoutEffect(() => {
    const handler = (e: BeforeInstallPromptEvent) => {
      setSupportsPWA(true);
      setPromptInstall(e);
    };
    window.addEventListener("beforeinstallprompt", handler as any);

    return () => window.removeEventListener("transitionend", handler as any);
  }, []);

  const promptToInstall = () => {
    if (!promptInstall) {
      return;
    }
    promptInstall.prompt();
  };

  return (
    <Grid container spacing={0} className={classes.root}>
      <Grid item xs={12}>
        <NavBar userID={userID} mobileOpen={mobileOpen} setMobileOpen={setMobileOpen} />
      </Grid>
      <Grid item xs={12}>
        <div className={classes.toolbar} />
        <Switch>
          <Route path='/verification/success' component={VerificationSuccess} />
          <Route path='/verification/error' component={VerificationError} />
          <Route path='/terms-and-conditions' component={TermsAndPrivacy} />
          <Route path='/privacy-policy' component={TermsAndPrivacy} />
          <Route path='/dashboard' render={(props) => <Dashboard {...props} setUserID={setUserID} mobileOpen={mobileOpen} setMobileOpen={setMobileOpen} supportsPWA={supportsPWA} promptToInstall={promptToInstall} />} />
          <Route path='/' component={Landing} />
        </Switch>
      </Grid>
    </Grid>
  )
}

export default Main
