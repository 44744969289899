import React, { useState, createContext } from 'react';

export const ThemeContext = createContext(
  {
    darkMode: true,
    toggleTheme: () => { },
  }
)

const ThemeContextProvider: React.FC = ({ children }) => {
  const [darkMode, setDarkMode] = useState<boolean>(!(localStorage.getItem('theme') === 'light'));
  const toggleTheme = () => {
    if (darkMode) {
      setDarkMode(false);
      localStorage.setItem('theme', 'light');
    } else {
      setDarkMode(true);
      localStorage.removeItem('theme');
    }
  }

  return (
    <ThemeContext.Provider
      value={{
        darkMode: darkMode,
        toggleTheme: toggleTheme,
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
};

export default ThemeContextProvider;