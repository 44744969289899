import React, { Fragment, useEffect, useState } from 'react';
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import { Button, Grid, Hidden, Typography } from '@material-ui/core';
import { useCallApi } from '../../../@utils';
import { DialogFeatures, initialDialogFeatures, initialSnippet, initialSnippetCollectionList, Options, Snippet, SnippetCollection, SnippetCollectionListLoadState, SnippetLoadState } from '../../../@types';
import { LibraryAdd } from '@material-ui/icons';
import CodeEditorCard from '../../../components/cards/CodeEditorCard';
import DialogAlert from '../../../components/utility/DialogAlert';
import NewCollectionForm from '../../../components/forms/NewCollectionForm';
import StatsCardGroup from '../../../components/structure/StatsCardGroup';
import SnippetCollectionListCard from '../../../components/cards/SnippetCollectionListCard';
import ResponseSnackbar from '../../../components/utility/ResponseSnackbar';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    height: '100%',
    '& #multiCard': {
      padding: 0,
    },
  },
  emptyContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    '& .MuiButton-root': {
      marginTop: 20,
    },
  },
  hideScrollbarContainer: {
    height: '100%',
    '& ::-webkit-scrollbar': {
      display: 'none',
    },
  },
  '@media only screen and (max-height: 1180px)': {
    root: {
      '& #multiCard': {
        padding: '12px 0 12px 0',
      },
    },
  },
}));

interface MySnippetsProps {
  userID: number;
  setDisableAdd: React.Dispatch<React.SetStateAction<boolean>>;
}

const MySnippets: React.FC<MySnippetsProps> = ({ userID, setDisableAdd }: MySnippetsProps) => {
  const classes = useStyles();
  const theme = useTheme();
  const [snippetCollectionPage, setSnippetCollectionPage] = useState<number>(1);

  const [snippetCollectionListLoadState, snippetCollectionListOptions]: [SnippetCollectionListLoadState, Options] = useCallApi(
    `snippet/all/${userID}/?page=${snippetCollectionPage}`,
    initialSnippetCollectionList,
    'GET',
    false,
    'PRIVATE'
  );

  const [snippetCollectionList, setSnippetCollectionList] = useState<Array<SnippetCollection>>(snippetCollectionListLoadState.data.results);

  // Wait until userID is obtained before loading snippetCollectionList
  useEffect(() => {
    if (userID !== 0) snippetCollectionListOptions.setShouldFetch(true);
  }, [userID, snippetCollectionListOptions]);

  const [firstSnippetLoadState, firstSnippetOptions]: [SnippetLoadState, Options] = useCallApi(
    `snippet/first/${userID}/`,
    initialSnippet,
    'GET',
    false,
    'PRIVATE',
  )

  const [snippetView, setSnippetView] = useState<Snippet>(firstSnippetLoadState.data);

  // Wait until userID is obtained before loading first snippet
  useEffect(() => {
    if (userID !== 0) firstSnippetOptions.setShouldFetch(true);
  }, [userID, firstSnippetOptions]);

  useEffect(() => {
    setSnippetView(firstSnippetLoadState.data);
  }, [firstSnippetLoadState.data])

  // Snackbar Handle Success
  const [message, setMessage] = useState<string>('');

  // Handle New Collection (when no collections)
  const [dialogFeatures, setDialogFeatures] = useState<DialogFeatures>(initialDialogFeatures);
  const handleNewCollection = () => {
    setDialogFeatures({
      open: true,
      title: 'Add Collection',
      color: `${theme.palette.primary.main}80`,
      customBodyActions: <NewCollectionForm
        userID={userID}
        triggerClose={() => {
          setDialogFeatures(initialDialogFeatures)
        }}
        setMessage={setMessage}
      />,
    });
  };

  // Handle Editing Flag (Affects both List and Code Editor card)
  const [editingFlag, setEditingFlag] = useState<boolean>(false);

  // Do not allow user to add snippet without any collections
  useEffect(() => {
    if (snippetCollectionListLoadState.data.results.length === 0) {
      setDisableAdd(true);
    }
  }, [snippetCollectionListLoadState.data.results, setDisableAdd]);

  return (
    <Grid container spacing={3} className={classes.root}>
      {snippetCollectionListLoadState.data.results.length === 0 ? (
        <Grid item xs={12} className={classes.emptyContainer}>
          <Typography variant='h5' component='h5' gutterBottom>Looks like you haven't created any snippet collections</Typography>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleNewCollection}
            startIcon={<LibraryAdd />}
          >
            Create one now!
          </Button>
        </Grid>
      ) : (
          <Fragment>
            <Grid item xs={12} md={5} lg={4} xl={3} className={classes.hideScrollbarContainer}>
              <SnippetCollectionListCard
                userID={userID}
                snippetCollectionListLoadState={snippetCollectionListLoadState}
                snippetCollectionListOptions={snippetCollectionListOptions}
                snippetCollectionList={snippetCollectionList}
                setSnippetCollectionList={setSnippetCollectionList}
                snippetView={snippetView}
                setSnippetView={setSnippetView}
                editingFlag={editingFlag}
                setEditingFlag={setEditingFlag}
                setSnippetCollectionPage={setSnippetCollectionPage}
              />
            </Grid>
            <Hidden smDown>
              <Grid item md={7} lg={8} xl={6} className={classes.hideScrollbarContainer}>
                <CodeEditorCard
                  userID={userID}
                  isLoading={snippetCollectionListLoadState.isLoading}
                  snippetView={snippetView}
                  editingFlag={editingFlag}
                  setEditingFlag={setEditingFlag}
                />
              </Grid>
            </Hidden>
            <Hidden lgDown>
              <Grid item xl={3} className={classes.hideScrollbarContainer} id="multiCard">
                <StatsCardGroup userID={userID} />
              </Grid>
            </Hidden>
          </Fragment>
        )}
      <DialogAlert dialogFeatures={dialogFeatures} setDialogFeatures={setDialogFeatures} />
      <ResponseSnackbar message={message} setMessage={setMessage} severity='success' />
    </Grid >
  )
}

export default MySnippets
