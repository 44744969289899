import React, { useState } from 'react';
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import { Divider, Grid, Link, Typography } from '@material-ui/core';
import { Instagram } from '@material-ui/icons';
import { DialogFeatures, initialDialogFeatures } from '../../@types';
import DialogAlert from '../utility/DialogAlert';
import CreatorsSocialButtons from '../buttons/CreatorsSocialButtons';
import { version } from '../../../package.json';
import Logo from '../../assets/images/logo.svg';

const useStyles = makeStyles<Theme>((theme: Theme) => ({
  footer: {
    paddingBottom: '2rem',
    '& .MuiGrid-root': {
      display: 'flex',
      justifyContent: 'space-evenly',
      alignItems: 'center',
      '& span': {
        display: 'flex',
        width: 100,
        justifyContent: 'center',
        '& a': {
          display: 'flex',
          alignItems: 'center',
          width: 'fit-content',
          padding: '4px 0 2px',
          borderBottom: '1px solid',
          borderBottomColor: theme.palette.secondary.main,
          textDecoration: 'none',
          cursor: 'pointer',
          '& .MuiSvgIcon-root': {
            marginRight: 8,
            fontSize: '1rem',
          },
        },
      },
    },
  },
  version: {
    fontSize: 9,
    color: theme.palette.text.disabled
  },
  aboutDialog: {
    '& p': {
      paddingTop: 10,
      paddingBottom: 10,
    }
  },
  copyright: {
    marginTop: 30,
  },
  '@media only screen and (max-width: 600px)': {
    footer: {
      paddingBottom: '1rem',
      '& .MuiGrid-root': {
        flexDirection: 'column',
        '& a': {
          marginBottom: 30,
        },
      },
    },
    copyright: {
      marginTop: 10,
    },
  },
}));

const Footer: React.FC = () => {
  const classes = useStyles();
  const theme = useTheme();

  const [dialogFeatures, setDialogFeatures] = useState<DialogFeatures>(initialDialogFeatures)
  const handleAbout = () => {
    setDialogFeatures({
      open: true,
      title: 'About CodeCache',
      subtitle: <Typography variant="caption" component="p" className={classes.version}>Version: {version}</Typography>,
      icon: <img src={Logo} height={80} width={80} alt="CodeCache Logo" />,
      color: `${theme.palette.primary.main}80`,
      customBodyActions:
        <Grid container spacing={0} className={classes.aboutDialog}>
          <Grid item xs={12}>
            <Typography variant="body2" component="p">
              CodeCache was created as a platform to store those juicy one-liners and one-blocks of code that developers and people learning to code use everyday.
            </Typography>
            <Typography variant="h6" component="p">
              Contact the creator with the social links below to find out more!
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <CreatorsSocialButtons />
          </Grid>
        </Grid>
    })
  };

  return (
    <Grid container spacing={0} className={classes.footer}>
      <Grid item xs={12}>
        <Typography variant="body2" component="span">
          <Link onClick={handleAbout} color="textPrimary">
            About
          </Link>
        </Typography>
        <Divider orientation="vertical" flexItem />
        <Typography variant="body2" component="span">
          <Link href="/privacy-policy" color='textPrimary'>
            Privacy
          </Link>
        </Typography>
        <Divider orientation="vertical" flexItem />
        <Typography variant="body2" component="span">
          <Link href="/terms-and-conditions" color="textPrimary">
            Terms
          </Link>
        </Typography>
        <Divider orientation="vertical" flexItem />
        <Typography variant="body2" component="span">
          <Link href='mailto:codecache.contact@gmail.com' color="textPrimary" rel="noopener" aria-label="Email">
            Support
          </Link>
        </Typography>
        <Divider orientation="vertical" flexItem />
        <Typography variant="body2" component="span">
          <Link href="https://www.instagram.com/codecache.io/" color="textPrimary" target='_blank' rel="noopener" aria-label="Instagram">
            <Instagram />Follow Us!
          </Link>
        </Typography>
      </Grid>
      <Grid item xs={12} className={classes.copyright}>
        <Typography variant="caption" component="div">© {new Date().getFullYear()} CodeCache. All rights reserved.</Typography>
      </Grid>
      <DialogAlert dialogFeatures={dialogFeatures} setDialogFeatures={setDialogFeatures} />
    </Grid>
  )
}

export default Footer
