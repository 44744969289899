import React from 'react';
import ThemeContextProvider from './context/ThemeContext';
import AppTheme from './components/common/AppTheme';

function App() {
  return (
    <ThemeContextProvider>
      <AppTheme />
    </ThemeContextProvider>
  );
}

export default App;
