import React, { Fragment, useState } from 'react';
import { makeStyles, Theme, } from '@material-ui/core/styles';
import { IconButton, ListItemIcon, ListItemText, Menu, MenuItem } from '@material-ui/core';
import { Delete, Edit, MoreVert } from '@material-ui/icons';

const useStyles = makeStyles((theme: Theme) => ({
  menuPaper: {
    '& #delete': {
      color: theme.palette.error.main,
      '& .MuiSvgIcon-root': {
        color: theme.palette.error.main,
      },
    },
  },
}));

interface SnippetMoreOptionsButtonProps {
  handleEditSnippetDetails: () => void;
  handleDelete: () => void;
}

const SnippetMoreOptionsButton: React.FC<SnippetMoreOptionsButtonProps> = ({ handleEditSnippetDetails, handleDelete }: SnippetMoreOptionsButtonProps) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  return (
    <Fragment>
      <IconButton onClick={(event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget)}>
        <MoreVert />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        getContentAnchorEl={null}
        className={classes.menuPaper}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem
          onClick={() => {
            handleEditSnippetDetails();
            setAnchorEl(null);
          }}
        >
          <ListItemIcon>
            <Edit fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Edit Details" />
        </MenuItem>
        <MenuItem
          id="delete"
          onClick={() => {
            handleDelete();
            setAnchorEl(null);
          }}
        >
          <ListItemIcon>
            <Delete fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Delete" />
        </MenuItem>
      </Menu>
    </Fragment>
  )
}

export default SnippetMoreOptionsButton
