import WhatIsCodeCache from '../images/landing/what_is_codecache.svg';
import UseCases from '../images/landing/use_cases.svg';
import AllYourSnippetsAtYourFingertips from '../images/landing/all_your_snippets_at_your_fingertips.svg';
import DownloadAsAnApp from '../images/landing/download_as_an_app.svg';
import SimpleInterface from '../images/landing/simple_interface.svg';
import ManyModes from '../images/landing/many_modes.svg';
import CustomiseYourTheme from '../images/landing/customise_your_theme.svg';
import { LandingContent } from '../../@types';

export const landingContent: LandingContent = {
  header: `The lightweight code snippet manager built for developers and learners`,
  subheader: `Never forget the code that you write by storing them securely on CodeCache, entirely for free!`,
  sections: [
    {
      title: `What is CodeCache?`,
      image: WhatIsCodeCache,
      content: `CodeCache is a platform that lets you add, edit and view your code snippets. With support for over 20 modes from Python to JSON snippets, CodeCache allows you to simplify your programming, and easily prepare for that upcoming exam or interview.`,
    },
    {
      title: `Use Cases`,
      image: UseCases,
      content: `Got a programming class that requires you to memorise bits of code? Preparing for a technical interview but cannot remember the solution to that particular greedy algorithm question? Or you just want a place to store your favourite functions? That's exactly what CodeCache is for.`,
    },
    {
      title: `All Your Snippets At Your Fingertips`,
      image: AllYourSnippetsAtYourFingertips,
      content: `Flexible and lightweight, use the power of autocomplete and syntax highlighting just like in your favourite text editor.`,
    },
    {
      title: `Download As An App`,
      image: DownloadAsAnApp,
      content: `Due to progressive web app technologies, you can now download CodeCache as an app directly from your browser! Simply create an account and visit the settings page. Alternatively, for Android devices, tap on the browser's more options button and then tap "Add to Home Screen". For IOS devices, tap on the sharing button and choose "Add to Home Screen". If you are using a computer, you can also download CodeCache by clicking the browser's more options button and then clicking "Install CodeCache".`,
    },
  ],
  features: [
    {
      title: `Simple Interface`,
      image: SimpleInterface,
      content: `Easy to navigate, never lose a snippet.`,
    },
    {
      title: `Many Modes`,
      image: ManyModes,
      content: `With over 25 different modes, choose from languages like JavaScript and OCaml.`,
    },
    {
      title: `Choose Your Theme`,
      image: CustomiseYourTheme,
      content: `Customizable editor to make snippets your own.`,
    }
  ]
}