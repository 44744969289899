import React, { useState } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Button, FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select, TextField } from '@material-ui/core';
import { Collection, CollectionListLoadState, EditSnippetDetails, EditSnippetDetailsIsFilled, initialCollectionList, Options, Snippet } from '../../@types';
import { MODES, THEMES } from '../../@constants';
import { useCallApi } from '../../@utils';
import LoadingAnimation from '../utility/LoadingAnimation';

const useStyles = makeStyles((theme: Theme) => ({
  optionsGroup: {
    display: 'flex',
    justifyContent: 'space-evenly',
  },
  formfield: {
    width: '100%',
    '& .MuiIconButton-root': {
      color: theme.palette.text.secondary,
      padding: 8,
    },
  },
  collectionSelect: {
    minWidth: 200,
    width: '100%'
  },
  formControl: {
    minWidth: 120,
    width: '100%',
    marginBottom: 12,
    '& .ace_scrollbar': {
      display: 'none'
    }
  },
  menuPaper: {
    maxHeight: 315,
  },
  buttonGroup: {
    display: 'flex',
    justifyContent: 'space-between',
    '& > * ': {
      boxShadow: 'none',
      width: '48%',
    },
  },
}));

interface EditSnippetDetailsFormProps {
  userID: number;
  triggerClose: () => void;
  setMessage: React.Dispatch<React.SetStateAction<string>>;
  setSnackbarSeverity: React.Dispatch<React.SetStateAction<'success' | 'info' | 'error'>>;
  snippetView: Snippet;
  form: EditSnippetDetails;
  setForm: React.Dispatch<React.SetStateAction<EditSnippetDetails>>;
  setEditingFlag: React.Dispatch<React.SetStateAction<boolean>>;
};

const EditSnippetDetailsForm: React.FC<EditSnippetDetailsFormProps> = (
  {
    userID,
    triggerClose,
    setMessage,
    setSnackbarSeverity,
    snippetView,
    form,
    setForm,
    setEditingFlag
  }: EditSnippetDetailsFormProps) => {
  const classes = useStyles();

  const [newForm, setNewForm] = useState(form);

  const [isFilled, setIsFilled] = useState<EditSnippetDetailsIsFilled>({
    snippet_name: true,
    programming_language: true,
    theme: true,
    collection_id: true
  });

  const handleUpdate = () => {
    //Show Errors
    for (let [field, value] of Object.entries(newForm)) {
      setIsFilled(prev => ({
        ...prev,
        [field]: !value ? false : true
      }));
    };

    // Validation
    if (!Object.values(newForm).includes('')) {
      setForm({
        snippet_name: newForm.snippet_name,
        snippet_content: newForm.snippet_content,
        programming_language: newForm.programming_language,
        theme: newForm.theme,
        collection_id: newForm.collection_id,
      });
      if (
        snippetView.snippet_name === newForm.snippet_name &&
        snippetView.programming_language === newForm.programming_language &&
        snippetView.theme === newForm.theme &&
        snippetView.collection_id === newForm.collection_id
      ) {
        setEditingFlag(false);
      } else {
        setEditingFlag(true);
        setSnackbarSeverity('info');
        setMessage('Details Updated! Please Save Your Changes.');
      }
      triggerClose();
    };
  };

  const [collectionListLoadState]: [CollectionListLoadState, Options] = useCallApi(
    `collection/${userID}/`,
    initialCollectionList,
    'GET',
    true,
    'PRIVATE'
  );

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} className={classes.optionsGroup}>
        <FormControl className={classes.formfield} component="fieldset">
          <TextField
            fullWidth
            id="snippet_name"
            name="Snippet Name"
            label='Snippet Name'
            autoComplete="off"
            variant="outlined"
            color="secondary"
            defaultValue={newForm.snippet_name}
            inputProps={{ maxLength: 50 }}
            onChange={(e) => {
              setNewForm(prev => ({
                ...prev,
                snippet_name: e.target.value
              }));
              setIsFilled(prev => ({
                ...prev,
                snippet_name: e.target.value.length === 0 ? false : true
              }));
              e.persist();
            }}
            error={!isFilled.snippet_name}
            helperText={isFilled.snippet_name ? `${newForm.snippet_name.length}/50` : 'Collection Name cannot be blank'}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormControl variant="outlined" className={classes.collectionSelect}>
          <InputLabel id="collection-select" color="secondary">Select Collection</InputLabel>
          <Select
            labelId="collection-select"
            value={newForm.collection_id}
            color="secondary"
            onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
              setNewForm(prev => ({
                ...prev,
                collection_id: event.target.value as number,
              }));
            }}
            label="Select Collection"
            MenuProps={{ classes: { paper: classes.menuPaper } }}
            error={!isFilled.collection_id}
          >
            {collectionListLoadState.data?.map((collection: Collection, index: number) => {
              return <MenuItem key={collection.id} value={collection.id}>{collection.collection_name}</MenuItem>
            })}
            {collectionListLoadState.isLoading && <LoadingAnimation />}
          </Select>
          <FormHelperText error={!isFilled.collection_id}>{isFilled.collection_id ? ' ' : 'Please select a collection'}</FormHelperText>
        </FormControl>
      </Grid>
      <Grid item xs={6} sm={3}>
        <FormControl variant="outlined" className={classes.formControl}>
          <InputLabel id="theme-select" color="secondary">Theme</InputLabel>
          <Select
            labelId="theme-select"
            value={newForm.theme}
            color="secondary"
            onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
              setNewForm(prev => ({
                ...prev,
                theme: event.target.value as string,
              }));
            }}
            label="Theme"
            MenuProps={{ classes: { paper: classes.menuPaper } }}
          >
            {THEMES.map((theme) => {
              return <MenuItem key={theme.value} value={theme.value}>{theme.name}</MenuItem>
            })}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={6} sm={3}>
        <FormControl variant="outlined" className={classes.formControl}>
          <InputLabel id="language-select" color="secondary">Mode</InputLabel>
          <Select
            labelId="language-select"
            color="secondary"
            value={newForm.programming_language}
            onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
              setNewForm(prev => ({
                ...prev,
                programming_language: event.target.value as string,
              }));
            }}
            label="Mode"
            MenuProps={{ classes: { paper: classes.menuPaper } }}
          >
            {MODES.map((mode) => {
              return <MenuItem key={mode.value} value={mode.value}>{mode.name}</MenuItem>
            })}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} className={classes.buttonGroup}>
        <Button variant='contained' onClick={triggerClose}>
          Cancel
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleUpdate}
          disabled={newForm === form}
        >
          Update
        </Button>
      </Grid>
    </Grid>
  )
}

export default EditSnippetDetailsForm