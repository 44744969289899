export interface DonutStatsInterface {
  name: string;
  y: number;
};

export interface DonutStatsLoadStateInterface {
  isLoading: boolean;
  isError: boolean;
  data: Array<DonutStatsInterface>;
};

export const initialDonutStats: Array<DonutStatsInterface> = [
  {
    name: '',
    y: 0
  }
];
