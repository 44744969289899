import React, { useContext } from 'react'
import { createMuiTheme, ThemeOptions, Theme, ThemeProvider, CssBaseline } from '@material-ui/core';
import Gilroy from '../../assets/fonts/Gilroy-ExtraBold.woff';
import { ThemeContext } from '../../context/ThemeContext';
import AppRouter from './AppRouter';

const AppTheme: React.FC = () => {
  const currentThemeContext = useContext(ThemeContext);

  const gilroy = {
    fontFamily: 'Gilroy',
    fontStyle: 'normal',
    fontDisplay: 'swap' as 'swap',
    fontWeight: 400,
    src: `
      local('Gilroy'),
      local('Gilroy-ExtraBold'),
      url(${Gilroy}) format('woff')
    `,
  };

  const common: ThemeOptions = {
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 960,
        lg: 1280,
        xl: 1920,
      }
    },
    typography: {
      fontFamily: "'Gilroy', sans-serif !important",
    },
    overrides: {
      MuiCssBaseline: {
        '@global': {
          '@font-face': [gilroy],
        },
      },
    },
  };

  const dark: Theme = createMuiTheme({
    palette: {
      type: 'dark',
      primary: {
        main: '#F63F3A',
      },
      secondary: {
        main: '#FEBC2C',
      },
      background: {
        default: '#212529',
        paper: '#2A2E32',
      },
    },
    ...common
  });

  const light: Theme = createMuiTheme({
    palette: {
      type: 'light',
      primary: {
        main: '#7291EC',
      },
      secondary: {
        main: '#F8A23B',
      },
      background: {
        default: '#fafafa',
        paper: '#f2f2f2',
      },
    },
    ...common
  });



  return (
    <ThemeProvider theme={currentThemeContext.darkMode ? dark : light}>
      <CssBaseline />
      <AppRouter />
    </ThemeProvider>
  );
}

  export default AppTheme
